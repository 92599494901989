import {
  faCheck,
  faPaperclip,
  faPlus,
  faSearch,
  faTimes,
  faTrash,
  faEdit,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { AddEditRecordModal } from "../../../../common/AddEditRecordModal";
import CustomTabs from "../../../../common/PageLayout/components/CustomTabs";
import List from "../../../../common/PageLayout/components/List";
import ListContainer from "../../../../common/PageLayout/components/ListContainer";
import ListHeader from "../../../../common/PageLayout/components/ListHeader";
import { IMAGES } from "../../../../constants";
import {
  filterDropdown,
  MENU,
  PaymentStatus,
  sortDropdownGetPaid,
  SORT_BY,
  TAKE,
  sortDropdownEstimates,
} from "../../../../constants/globalConstants";
import { cutLengthyString } from "../../../../modules/cutLengthyString";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";
import { formatCurrency } from "../../../../modules";
import {
  getSortByGetPaidLS,
  setSortByGetPaidLS,
  removeSortByGetPaidLS,
  setFilterByGetPaidLS,
  removeFilterByGetPaidLS,
  getFilterByGetPaidLS,
  removeContactsFilterGetPaidLS,
  getSearchTextGetPaidLS,
  setContactsFilterGetPaidLS,
  getContactsFilterGetPaidLS,
  setSearchTextGetPaidLS,
  removeSearchTextGetPaidLS,
  setBucketsFilterGetPaidLS,
  getBucketsFilterGetPaidLS,
  removeBucketsFilterGetPaidLS,
} from "../../../../modules/localStorage";
import {
  deleteAllForecasts,
  deleteAllReceivables,
  deleteForecasts,
  deleteInvoiceByKeys,
  getAllReceivablesContactNames,
  getBuckets,
} from "../../../../repositories/invoiceRepository";
import { getHighlightedText } from "../../../../modules/getHighlightedText";
import { ascendingSortByDate } from "../../../../modules/sortByDate";
import { renderSortIconButton } from "../../../../common/IconButton/IconButton";
import { renderFilterIconButton } from "../../../../common/IconButton/FilterIconButton";
import { CustomModal } from "../../../../common/CustomModal/CustomModal";
import { filterByDueDate, sortRecords, filterRecords } from "../common";
import { searchFilter } from "../common/searchFilter";
import DropdownComponents from "../common/DropdownComponents";
import { useAuth } from "../../../../contexts";
import { getStartAndEndDates } from "../../../../modules/getDates";
import { IconButton, Popover, Whisper } from "rsuite";
import {
  deleteBlackIcon,
  deleteIcon,
  searchBlackIcon,
  searchIcon,
} from "../../../../constants/images";
import { getFilterObj } from "../common/getFilterObject";
import AddActionPopover from "../../../../common/AddActionPopover";
import { COLORS } from "../../../../constants/theme";
import AddEditForecastBudgetModal from "../../../Cashflow/components/ForecastBudget/AddEditForecastBudgetModal";
import EditBillsOrInvoices from "../common/Edit/EditBillsOrInvoices";
import UploadInvoiceInBulk from "../common/Upload/UploadInvoiceInBulk";
import EditEstimatesInBulk from "../common/Edit/EditEstimatesInBulk";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function LeftView({ onAddClick }: any) {
  const {
    totalRecords,
    recordsReceivable: _records,
    getRecords,
    setRecords: setAuthRecords,
    recordsLoading,
    fetchCashflowReport,
    forecasts,
    fetchForecasts,
    selectedScenario,
  } = useAuth();

  const [isEstimates, setIsEstimates] = useState(false);

  const query = useQuery();

  useEffect(() => {
    const tab = query.get("tab");
    if (tab === "estimates") {
      setIsEstimates(true);
    } else {
      setIsEstimates(false);
    }
  }, [query]);

  // const { setPaymentType, showDrawer, setStep } = usePaymentsContext();

  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [totalForecasts, setTotalForecasts] = useState<number>(0);
  const [records, setRecords] = useState<any[]>(_records);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "sent");
  const [showOlderRecords, ShowOlderRecords] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchText, setSearchText] = useState(getSearchTextGetPaidLS() || "");
  const [openSearch, setOpenSearch] = useState<Boolean>(
    getSearchTextGetPaidLS() ? true : false
  );
  const [backendValue, setBackendValue] = useState("");
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [filterDropdownList, setfilterDropdownList] = useState(filterDropdown);
  const sortBy: any = getSortByGetPaidLS()
    ? getSortByGetPaidLS().split("/")
    : ["", ""];
  const filterBy = getFilterByGetPaidLS()
    ? getFilterByGetPaidLS().split("/")
    : ["", ""];
  const [contacts, setContacts] = useState<string[]>(
    getContactsFilterGetPaidLS()
  );
  const [buckets, setBuckets] = useState<string[]>(getBucketsFilterGetPaidLS());

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSortSelected, setIsSortSelected] = useState(
    getSortByGetPaidLS() ? true : false
  );

  const [isFilterSelected, setIsfilterSelected] = useState(
    getFilterByGetPaidLS() ? true : false
  );

  const [datePickerValue, setDatePickerValue] = useState<DateRangePickerValue>([
    new Date(2022, 11, 1),
    new Date(2022, 11, 5),
  ]);
  const [sortMenu, setSortMenu] = useState({
    mainmenu: sortBy[0],
    submenu: sortBy[1],
  });
  const [filterMenu, setfilterMenu] = useState({
    mainmenu: filterBy[0],
    submenu: filterBy[1],
  });

  const [editInBulk, setEditInBulk] = useState<boolean>(false);
  const [editRecordState, setEditRecordState] = useState<Record<string, any>[]>(
    []
  );

  const [uploadInBulk, setUploadInBulk] = useState<boolean>(false);

  const isForecastBudgetTab = () =>
    activeTab === "estimates" || activeTab === "budgets";

  const isForecastTab = () => activeTab === "estimates";
  const isBudgetTab = () => activeTab === "budgets";

  // console.log("GET PAID RECORDS >>>>> ");

  const whichRecordsFilter = () => {
    if (activeTab === "all") {
      return records;
    } else if (activeTab === "paid") {
      return records.filter(
        (record: any) =>
          record.status === PaymentStatus.Paid ||
          record.status === PaymentStatus.PartiallyPaid
      );
    } else if (activeTab === "unpaid") {
      return records.filter(
        (record: any) =>
          record.status === "Unpaid" || record.status === "PartiallyPaid"
      );
    } else if (activeTab === "estimates") {
      return forecasts.filter(
        (forecast: any) => forecast.settings.type === "Receivable"
      );
    } else if (activeTab === "draft") {
      return [];
    } else {
      return records;
    }
  };

  useEffect(() => {
    if (_records.length === 0) {
      getRecords();
    }
  }, []);

  useEffect(() => {
    setRecords(_records);
  }, [_records]);

  useEffect(() => {
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;
    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );
    if (openSearch && !searchText) {
      // backendValue is sortField
      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(
          undefined,
          0,
          "Receivable",
          backendValue,
          sortOrder,
          filterObj
        );
      }

      setSkip(0);
      return;
    }
    if (!openSearch && !searchText) {
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      setSkip(0);
      if (isForecastTab()) {
        // console.log("in use memo isForecastTab delayDebounceFn");
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(
          undefined,
          0,
          "Receivable",
          backendValue,
          sortOrder,
          filterObj
        );
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    (async () => {
      const sortOrder =
        sortMenu.submenu === MENU.ASCENDING ||
        sortMenu.submenu === MENU.NEWEST_TO_OLDEST
          ? SORT_BY.ASC
          : SORT_BY.DESC;

      let filterObj = getFilterObj(
        filterMenu,
        activeTab,
        datePickerValue,
        searchText,
        contacts,
        buckets
      );

      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(
          undefined,
          0,
          "Receivable",
          backendValue,
          sortOrder,
          filterObj
        );
      }
      setSkip(0);
      await new Promise((resolve) => setTimeout(resolve, 300));
    })();
  }, [activeTab, sortMenu.mainmenu, sortMenu.submenu]);

  useEffect(() => {
    (async () => {
      const sortOrder =
        sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
          ? SORT_BY.ASC
          : SORT_BY.DESC;
      let filterObj = getFilterObj(
        filterMenu,
        activeTab,
        datePickerValue,
        searchText,
        contacts,
        buckets
      );

      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(
          undefined,
          0,
          "Receivable",
          backendValue,
          sortOrder,
          filterObj
        );
      }

      setSkip(0);
      await new Promise((resolve) => setTimeout(resolve, 300));
    })();
  }, [filterMenu.mainmenu, filterMenu.submenu]);

  useEffect(() => {
    (async () => {
      const result = await getAllReceivablesContactNames();
      const uniqueResults: any = _.uniqBy(result, "label");
      filterDropdownList[1].children = uniqueResults;
      filterDropdownList[2].children = await getBuckets({
        type: "Receivable",
      });
      setfilterDropdownList(filterDropdownList);
      let itemId = parseInt(
        searchParams.get("selectedBillId")
          ? searchParams.get("selectedBillId")
          : recordsToDisplay?.length
          ? recordsToDisplay[0].id
          : ""
      );
      window.localStorage.setItem("selectedInvoiceId", itemId.toString());
    })();
  }, [selectedScenario]);

  useEffect(() => {
    const records = whichRecordsFilter();
    if (isForecastBudgetTab()) {
      const result = records.map(({ name }: any) => ({
        value: `${MENU.BY_CONTACT}/${name}`,
        label: name,
      }));
      const uniqueResults: any = _.uniqBy(result, "label");
      filterDropdownList[1].children = uniqueResults;
      setfilterDropdownList(filterDropdownList);
    } else {
      const result = records.map(({ contact }: any) => ({
        value: `${MENU.BY_CONTACT}/${contact?.displayName}`,
        label: contact?.displayName,
      }));
      const uniqueResults: any = _.uniqBy(result, "label");
      filterDropdownList[1].children = uniqueResults;
      setfilterDropdownList(filterDropdownList);
    }
  }, [records, forecasts]);

  let recordsToDisplay = useMemo(() => {
    if (isForecastBudgetTab()) {
      if (isForecastTab()) {
        let filteredRecords = forecasts.filter(
          (forecast: any) => forecast.settings.type === "Receivable"
        );

        setTotalForecasts(filteredRecords.length);

        return filteredRecords;
      } else return [];
    } else {
      let filteredRecords = whichRecordsFilter();
      if (!filteredRecords?.length) {
        return;
      }

      if (!showOlderRecords) {
        filteredRecords = filteredRecords.filter((record: any) =>
          moment(record.dueDate).isSameOrAfter(moment(new Date()), "day")
        );
      }
      const beforeFilter = _.cloneDeep(filteredRecords);
      if (searchText) {
        filteredRecords = searchFilter(searchText, filteredRecords);
      }
      if (!openSearch) {
        filteredRecords = _.cloneDeep(beforeFilter);
      }
      if (filterMenu.mainmenu && isFilterSelected) {
        if (filterMenu.submenu !== MENU.DATE_RANGE) {
          filteredRecords = filterRecords(
            filterMenu.submenu,
            filteredRecords,
            contacts
          );
        }
        if (filterMenu.submenu === MENU.DATE_RANGE && !isModalOpen) {
          filteredRecords = filterByDueDate(
            filteredRecords,
            moment(datePickerValue[0]).toISOString(),
            moment(datePickerValue[1]).toISOString()
          );
        }
      }

      if (sortMenu.mainmenu) {
        if (
          isSortSelected &&
          (sortMenu.mainmenu === MENU.BY_DATE ||
            sortMenu.mainmenu === MENU.BY_ISSUE_DATE ||
            MENU.BY_INVOICE ||
            MENU.BY_INVOICE)
        ) {
          filteredRecords = sortRecords(
            sortMenu.mainmenu,
            sortMenu.submenu,
            filteredRecords
          );
        }
      } else {
        filteredRecords = sortRecords(
          MENU.BY_DATE,
          MENU.NEWEST_TO_OLDEST,
          filteredRecords
        );
      }

      return _.cloneDeep(filteredRecords);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    showOlderRecords,
    records,
    searchText,
    openSearch,
    sortMenu.submenu,
    sortMenu.mainmenu,
    filterMenu.mainmenu,
    filterMenu.submenu,
    isSortSelected,
    isFilterSelected,
    isModalOpen,
    contacts.length,
    forecasts,
  ]);

  // console.log(" <<<<< forecasts >>>>> ", forecasts);

  const editRecords = () => {
    if (!selectedKeys.length) {
      showNotification({
        color: "red",
        message: "Please select a record or more to edit",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }
    // showNotification({
    //   id: "edit-data",
    //   loading: true,
    //   message: "Please wait while editing is in process",
    //   autoClose: false,
    //   disallowClose: true,
    // });
    // axios call

    try {
      // console.log("selected keys: ", selectedKeys);
      const selectedKeysAsIntegers = selectedKeys.map((key) => parseInt(key));
      const selectedRecords = recordsToDisplay.filter((record: any) =>
        selectedKeysAsIntegers.includes(record.id)
      );
      setEditInBulk(true);
      setEditRecordState(selectedRecords);
      // console.log("selected records ... ", selectedRecords);
    } catch (error) {
      console.log("Error inside editAllForecasts");
    }
  };

  const deleteRecords = async () => {
    if (!selectedKeys.length) {
      showNotification({
        color: "red",
        message: "Please select a record or more to delete",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }
    if (!window.confirm("Warning: Are you sure to delete?")) {
      return;
    }
    showNotification({
      id: "delete-data",
      loading: true,
      message: "Please wait while deletion is in process",
      autoClose: false,
      disallowClose: true,
    });

    try {
      let result: any = [];
      if (
        allSelected &&
        selectedKeys.length === _records.length &&
        !openSearch &&
        !isSortSelected &&
        !isFilterSelected
      ) {
        if (isForecastBudgetTab()) {
          result = await deleteAllForecasts();
        } else {
          result = await deleteAllReceivables();
        }
      } else {
        if (isForecastBudgetTab()) {
          result = await deleteForecasts(selectedKeys);
        } else {
          result = await deleteInvoiceByKeys(selectedKeys);
        }
      }

      if (result.data.success) {
        if (isForecastBudgetTab()) {
          fetchForecasts();
        } else {
          getRecords();
        }
        updateNotification({
          id: "delete-data",
          color: "teal",
          message:
            (allSelected
              ? totalRecords?.totalReceivable
              : selectedKeys.length) + " item(s) deleted!",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 5000,
        });
      } else {
        updateNotification({
          id: "delete-data",
          color: "red",
          message: `Cannot delete record(s)!`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }
    } catch (e) {
      updateNotification({
        id: "delete-data",
        color: "red",
        message: `Cannot delete record(s)!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const renderCardChildren = (bill: any) =>
    isForecastBudgetTab() ? (
      <div style={{ position: "relative" }}>
        {/* Header */}
        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
          <div className="flex-direction-row">
            <figure className="image" style={{ width: 40, height: 40 }}>
              <img
                className="is-rounded"
                src={IMAGES.company_logo_placeholder}
                alt=""
              />
            </figure>
            <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
              {getHighlightedText(cutLengthyString(bill?.name, 25), searchText)}
            </div>
          </div>
          {moment(bill?.settings?.endForecastDate).toISOString() <
            moment().toISOString() && (
            <button className="overdue-status">Expired!</button>
          )}
        </div>

        {/* Details */}
        <div className="flex-direction-row-space-between  date-amount-container">
          <div>
            <div className="card-date">
              {getHighlightedText(
                "Due " +
                  moment(bill.dueDate).format("MMM, DD, YYYY").toString() || "",
                searchText
              )}
            </div>
            <div className="card-code">
              {_.isEmpty(bill.repeats) || !bill.repeats.trim() ? (
                <i>No bill number.</i>
              ) : (
                getHighlightedText(`${bill.repeats}`, searchText)
              )}
            </div>
          </div>
          <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
            {bill.currency || "AED"}{" "}
            {/* {console.log("isForecastBudgetTab >>> ", isForecastBudgetTab())} */}
            {isForecastBudgetTab() ? (
              <>{getHighlightedText(bill?.amount, searchText)}</>
            ) : (
              <>
                {" "}
                {getHighlightedText(
                  formatCurrency(parseFloat(bill?.amount || "0"), 2) || "",
                  searchText
                )}{" "}
              </>
            )}
          </div>
        </div>
        {bill.image && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <FontAwesomeIcon icon={faPaperclip} />
          </div>
        )}
      </div>
    ) : (
      <div style={{ position: "relative" }}>
        {/* Header */}
        <div className="flex-direction-row is-justify-content-space-between">
          <div className="flex-direction-row">
            <figure className="image mr-3" style={{ width: 40, height: 40 }}>
              <img
                className="is-rounded"
                src={IMAGES.company_logo_placeholder}
                alt=""
              />
            </figure>
            <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
              {getHighlightedText(cutLengthyString(bill?.name, 25), searchText)}
            </div>
          </div>

          {bill.status === "Unpaid" &&
            moment(bill.dueDate).toISOString() < moment().toISOString() && (
              <button className="overdue-status">Overdue!</button>
            )}

          {bill.status === "PartiallyPaid" && (
            <button className="overdue-status orange">Partially Paid</button>
          )}
        </div>

        {/* Details */}
        <div className="flex-direction-row-space-between date-amount-container">
          <div>
            <div className="card-date">
              {bill.status === PaymentStatus.Paid
                ? getHighlightedText(
                    "Paid on " +
                      moment(
                        getLatestTransaction(bill?.linkedTransactions)
                          ?.paidOrFailedAt
                      ).format("MMM, DD, YYYY"),
                    searchText
                  )
                : getHighlightedText(
                    "Due " +
                      `${moment(bill.expectedDueDate).format("MMM, DD, YYYY")}`,
                    searchText
                  )}
            </div>
            <div className="card-code">
              {_.isEmpty(bill.invoiceNumber) || !bill.invoiceNumber.trim() ? (
                <i>No invoice number.</i>
              ) : (
                getHighlightedText(`#${bill.invoiceNumber}`, searchText)
              )}
            </div>
          </div>
          <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
            {bill.currency || "AED"}{" "}
            {getHighlightedText(
              formatCurrency(parseFloat(bill?.amountTotal || "0"), 2) || "",
              searchText
            )}
          </div>
        </div>
        {bill.image && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <FontAwesomeIcon icon={faPaperclip} />
          </div>
        )}
      </div>
    );

  const handleChangeSearch = (e: any) => {
    setSearchTextGetPaidLS(e.target.value);
    setSearchText(e.target.value);
  };

  const handleChangeOpenSearch = () => {
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;

    if (openSearch) {
      removeSearchTextGetPaidLS();
      setSearchText("");
      let filterObj = getFilterObj(
        filterMenu,
        activeTab,
        datePickerValue,
        searchText,
        contacts,
        buckets
      );
      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(
          undefined,
          0,
          "Receivable",
          backendValue,
          sortOrder,
          filterObj
        );
      }

      setSkip(0);
    }
    setOpenSearch(!openSearch);
  };

  const handleSelectedKeys = (keys: string[]) => {
    setSelectedKeys(keys);
  };

  const onSortSelect = (item: any, selectedPaths: any, event: any) => {
    const items = item.value.split("/");
    const mainmenu = items[0];
    const submenu = items?.[1] || "";
    setSortMenu({
      mainmenu,
      submenu,
    });
    const sortOrder =
      submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;
    setBackendValue(item.backendValue);
    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );
    getRecords(undefined, 0, "Receivable", backendValue, sortOrder, filterObj);
    setSkip(0);
    setSortByGetPaidLS(`${mainmenu}/${submenu}`);
    setIsSortSelected(true);
  };

  const handleLoadMore = async (skipVal: any) => {
    // fake delay
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;

    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );

    getRecords(
      undefined,
      skip + TAKE,
      "Receivable",
      backendValue,
      sortOrder,
      filterObj
    );
    setSkip(skip + TAKE);
    await new Promise((resolve) => setTimeout(resolve, 300));
  };

  const onFilterSelect = (item: any, selectedPaths: any, event: any) => {
    const items = item.value.split("/");
    const mainmenu = items[0];
    const submenu = items[1];
    setfilterMenu({
      mainmenu,
      submenu,
    });
    if (submenu === MENU.DATE_RANGE) {
      setIsModalOpen(true);
    }
    if (mainmenu === MENU.BY_DATE_FILTER) {
      setContacts([]);
      removeContactsFilterGetPaidLS();
      removeBucketsFilterGetPaidLS();
    }
    if (mainmenu === MENU.BY_CONTACT && submenu) {
      if (contacts.includes(submenu)) {
        const filteredContacts = contacts.filter(
          (contact) => contact !== submenu
        );
        setContacts(filteredContacts);
        setContactsFilterGetPaidLS(filteredContacts.join(","));
      } else {
        setContacts([...contacts, submenu]);
        setContactsFilterGetPaidLS([...contacts, submenu].join(","));
      }
    }

    if (mainmenu === MENU.BY_BUCKET && submenu) {
      if (buckets.includes(submenu)) {
        const filteredBuckets = buckets.filter((bucket) => bucket !== submenu);
        setBuckets(filteredBuckets);
        setBucketsFilterGetPaidLS(filteredBuckets.join(","));
      } else {
        setBuckets([...buckets, submenu]);
        setBucketsFilterGetPaidLS([...buckets, submenu].join(","));
      }
    }
    setFilterByGetPaidLS(`${mainmenu}/${submenu}`);
    setIsfilterSelected(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleSortClick = () => {
    if (isSortSelected) {
      setSortMenu({
        mainmenu: "",
        submenu: "",
      });
      removeSortByGetPaidLS();
    }
    setIsSortSelected(!isSortSelected);
  };

  const handleFilterClick = () => {
    if (isFilterSelected) {
      setfilterMenu({
        mainmenu: "",
        submenu: "",
      });
      removeFilterByGetPaidLS();
      setRecords(_records);
      removeContactsFilterGetPaidLS();
      removeBucketsFilterGetPaidLS();
      getRecords();
      setContacts([]);
    }
    setIsfilterSelected(!isFilterSelected);
  };

  const handleSortMenuClose = () => {
    setSortMenu({
      mainmenu: "",
      submenu: "",
    });

    removeSortByGetPaidLS();
  };

  const handleFilterMenuClose = () => {
    setfilterMenu({
      mainmenu: "",
      submenu: "",
    });
    setContacts([]);
    removeContactsFilterGetPaidLS();
    removeBucketsFilterGetPaidLS();
    removeFilterByGetPaidLS();
  };

  const [drawer, setDrawer] = React.useState(false);
  const [drawerType, setDrawerType] = React.useState<"forecast" | "budget">(
    "forecast"
  );

  const renderPlusButton = () => {
    return (
      <Fragment>
        <Whisper
          placement="bottomStart"
          trigger="click"
          speaker={({ onClose, left, top, className }: any, ref: any) => {
            const handleSelect = (eventKey: any) => {
              switch (eventKey) {
                case 1:
                  break;
                case 2:
                  onAddClick();
                  break;
                case 3:
                  // setDrawerType("budget");
                  // setDrawer(true);
                  break;
                case 4:
                  break;
                case 5:
                case 6:
                  setDrawerType("forecast");
                  setDrawer(true);
                  break;

                default:
                  break;
              }
              onClose();
            };
            return (
              <Popover
                ref={ref}
                className={className}
                style={{ left, top }}
                full
              >
                <AddActionPopover handleSelect={handleSelect} show="cash-in" />
              </Popover>
            );
          }}
        >
          <Button
            style={{
              backgroundColor: COLORS.greenBlue,
              color: "white",
              padding: "0 13px",
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />
          </Button>
        </Whisper>
        <AddEditForecastBudgetModal
          opened={drawer}
          onClose={() => {
            setDrawer(false);
          }}
          type={drawerType}
          section="Receivable"
        />
      </Fragment>
    );
  };

  return (
    <ListContainer>
      <ListHeader
        hasButton
        buttonTitle="Add an invoice"
        buttonAction={() => {
          onAddClick();
        }}
        title="Get Paid"
        renderButton={renderPlusButton}
      />
      <List
        style={{
          height: openSearch ? "calc(100vh - 360px)" : "calc(100vh - 245px)",
          overflowY: "scroll",
        }}
        handleChangeSearch={handleChangeSearch}
        setAllSelected={setAllSelected}
        handleChangeOpenSearch={handleChangeOpenSearch}
        handleSelectedKeys={handleSelectedKeys}
        openSearch={openSearch}
        searchText={searchText}
        totalRecords={
          isForecastBudgetTab() ? totalForecasts : totalRecords.totalPayable
        }
        paginate={
          isForecastBudgetTab() ? totalForecasts : totalRecords.totalReceivable
        }
        activeTab={activeTab}
        onLoadMore={async (skip: number) => {
          handleLoadMore(skip);
        }}
        renderActions={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {renderSortIconButton(
              handleSortClick,
              isSortSelected,
              sortMenu.mainmenu
            )}
            {renderFilterIconButton(
              handleFilterClick,
              isFilterSelected,
              filterMenu.mainmenu
            )}

            <CustomModal
              isOpen={isModalOpen}
              handleClose={handleModalClose}
              title={"Choose Date Range"}
              buttonText="Submit"
              handleButtonClick={handleModalClose}
            >
              <DateRangePicker
                label=""
                placeholder="Pick dates range"
                value={datePickerValue}
                onChange={setDatePickerValue}
              />
              <br />
            </CustomModal>
            <Tooltip
              label="Upload records"
              transition="scale-y"
              transitionDuration={300}
            >
              <div style={{ display: "flex", margin: "0 1px" }}>
                <IconButton
                  size="lg"
                  className="sort-icon-button"
                  onClick={() => {
                    setUploadInBulk(true);
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ fontSize: 21, color: "#717171" }}
                    />
                  }
                />
                {isEstimates ? (
                  <AddEditForecastBudgetModal
                    opened={uploadInBulk}
                    onClose={() => setUploadInBulk(false)}
                    type="forecast"
                    isDetailsOnly={false}
                    data={[]}
                    section="Receivable"
                  />
                ) : (
                  <UploadInvoiceInBulk
                    getRecords={getRecords}
                    uploadInBulk={uploadInBulk}
                    setUploadInBulk={setUploadInBulk}
                    title={"Upload Invoices In Bulk"}
                    recordType="Invoice"
                    contactType="Customer"
                    cashflowType="Receivable"
                    isBill={false}
                  />
                )}
              </div>
            </Tooltip>
            {/* {!isEstimates && (
              <Tooltip
                label="Edit records"
                transition="scale-y"
                transitionDuration={300}
              >
                <div style={{ display: "flex", margin: "0 1px" }}>
                  <IconButton
                    size="lg"
                    className="sort-icon-button"
                    onClick={editRecords}
                    icon={
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ fontSize: 21, color: "#717171" }}
                      />
                    }
                  />
                  <EditBillsOrInvoices
                    getRecords={getRecords}
                    editInBulk={editInBulk}
                    setEditInBulk={setEditInBulk}
                    records={editRecordState}
                    setRecords={setRecords}
                    setEditRecordState={setEditRecordState}
                    title={"Edit Invoices In Bulk"}
                    isBill={false}
                  />
                </div>
              </Tooltip>
            )} */}

            {isEstimates ? (
              <Tooltip
                label="Edit estimates"
                transition="scale-y"
                transitionDuration={300}
              >
                <div style={{ display: "flex", margin: "0 1px" }}>
                  <IconButton
                    size="lg"
                    className="sort-icon-button"
                    onClick={editRecords}
                    icon={
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ fontSize: 21, color: "#717171" }}
                      />
                    }
                  />
                  <EditEstimatesInBulk
                    selectedKeys={selectedKeys}
                    fetchForecasts={fetchForecasts}
                    setRecords={setRecords}
                    editInBulk={editInBulk}
                    setEditInBulk={setEditInBulk}
                    records={editRecordState}
                    setEditRecordState={setEditRecordState}
                    title={"Edit Estimate Details In Bulk"}
                    deleteRecords={deleteRecords}
                    cashflowType="Receivable"
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                label="Edit bills"
                transition="scale-y"
                transitionDuration={300}
              >
                <div style={{ display: "flex", margin: "0 1px" }}>
                  <IconButton
                    size="lg"
                    className="sort-icon-button"
                    onClick={editRecords}
                    icon={
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ fontSize: 21, color: "#717171" }}
                      />
                    }
                  />
                  <EditBillsOrInvoices
                    getRecords={getRecords}
                    setRecords={setRecords}
                    editInBulk={editInBulk}
                    setEditInBulk={setEditInBulk}
                    records={editRecordState}
                    setEditRecordState={setEditRecordState}
                    title={"Edit Bills In Bulk"}
                    isBill={true}
                    deleteRecords={deleteRecords}
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip
              label="Delete records"
              transition="scale-y"
              transitionDuration={300}
            >
              <div style={{ display: "flex" }}>
                <IconButton
                  className="sort-icon-button"
                  onClick={deleteRecords}
                  icon={
                    <img
                      src={selectedKeys?.length ? deleteBlackIcon : deleteIcon}
                    />
                  }
                />
              </div>
            </Tooltip>
            <div style={{ display: "flex" }}>
              <IconButton
                className="sort-icon-button"
                onClick={handleChangeOpenSearch}
                icon={<img src={openSearch ? searchBlackIcon : searchIcon} />}
              />
            </div>
          </div>
        )}
        list={recordsToDisplay}
        renderItem={renderCardChildren}
        listLoading={recordsLoading}
        renderTabs={() => (
          <CustomTabs
            active={activeTab}
            setActive={setActiveTab}
            tabs={[
              { key: "all", title: "All" },
              { key: "unpaid", title: "Upcoming" },
              { key: "paid", title: "Paid" },
              { key: "estimates", title: "Estimates" },
              // { key: "budgets", title: "Budgets" },
            ]}
            list={recordsToDisplay}
            totalRecords={totalRecords.totalReceivable}
          />
        )}
        renderDropdown={() => (
          <DropdownComponents
            isSortSelected={isSortSelected}
            isFilterSelected={isFilterSelected}
            sortMenu={sortMenu}
            filterMenu={filterMenu}
            contacts={contacts}
            buckets={buckets}
            onSortSelect={onSortSelect}
            onFilterSelect={onFilterSelect}
            handleFilterMenuClose={handleFilterMenuClose}
            handleSortMenuClose={handleSortMenuClose}
            filterDropdownList={filterDropdownList}
            sortDropdown={
              isForecastBudgetTab()
                ? sortDropdownEstimates
                : sortDropdownGetPaid
            }
          />
        )}
        selectItemId={parseInt(
          isForecastBudgetTab()
            ? searchParams.get("selectedForecastId")
            : searchParams.get("selectedBillId")
            ? searchParams.get("selectedBillId")
            : recordsToDisplay?.length
            ? recordsToDisplay[0].id
            : ""
        )}
        onCardClick={(clickedItem: any) => {
          if (isForecastBudgetTab()) {
            navigate({
              pathname: "/get-paid",
              search: `?tab=${searchParams.get("tab")}&selectedForecastId=${
                clickedItem.id
              }`,
            });
            window.localStorage.setItem(
              "selectedForecastIdGetPaid",
              clickedItem.id
            );
            window.localStorage.removeItem("selectedInvoiceId");
          } else {
            navigate({
              pathname: "/get-paid",
              search: `?tab=${searchParams.get("tab")}&selectedBillId=${
                clickedItem.id
              }`,
            });
            window.localStorage.setItem("selectedInvoiceId", clickedItem.id);
            window.localStorage.removeItem("selectedForecastIdGetPaid");
          }
        }}
        type="get-paid"
      />
      <AddEditRecordModal
        showModal={showAddModal}
        onCloseModal={() => {
          setShowAddModal(false);
        }}
        onCompleted={() => {
          setShowAddModal(false);
          getRecords();
        }}
        modalType="add"
        onlyReceivables
      />
    </ListContainer>
  );
}

export default LeftView;

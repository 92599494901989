import axios from "axios";
import { configs } from "../../../constants";

export const saveForecastsNBudget = async (dataToSave: any) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/financial_projection/save",
      {
        ...dataToSave,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const updateForecast = async (dataToSave: any) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/financial_projection/updateForecast",
      {
        ...dataToSave,
        estimateRecords: dataToSave.estimateRecords,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

import "./styles.css";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import moment from "moment";
import AddRecord from "../../../pages/Payments/components/AddRecord";
import { CustomDrawer } from "../../CustomDrawer";
import { InvoiceTypes } from "../../../constants/globalConstants";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import { InvoiceStatus, InvoiceStep } from "../../../constants/types";
import { updateInvoice } from "../../../repositories/invoiceRepository";
import { verifyTotalAmount } from "../../../repositories/currencyRepository";
import { useAuth } from "../../../contexts";
import { COLORS } from "../../../constants/theme";
import AddMorePayments from "../../AddMorePayments/AddMorePayments";
import AlertModal from "../../AlertModal/AlertModal";

interface EditModalType {
  showEditPayModal: boolean;
  setShowEditPayModal: any;
  selectedBill: any;
  invoiceType: InvoiceTypes;
  title: string;
  setSelectedRecord?: any;
}

function EditModal({
  showEditPayModal,
  setShowEditPayModal,
  selectedBill,
  invoiceType,
  title,
  setSelectedRecord,
}: EditModalType) {
  const [step, setStep] = useState<string>(InvoiceStep.INVOICE);
  const [data, setData] = useState<any[]>([]);
  const [deletedPaymentIds, setDeletedPaymentIds] = useState<any[]>([]);

  const [amountIsConverted, setAmountIsConverted] = useState<boolean>(false);
  const [convertedTotal, setConvertedTotal] = useState<any>(0);

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    message: "",
  });

  const modalReset = () => {
    setModalState({
      open: false,
      title: "",
      message: "",
    });
  };

  useEffect(() => {
    if (!showEditPayModal) {
      setData([]);
      setDeletedPaymentIds([]);
      setAmountIsConverted(false);
      setConvertedTotal(0);
      modalReset();
    }
  }, [showEditPayModal]);

  // useEffect(() => {
  //   if (!showEditPayModal) {
  //     console.log("data after false >>> ", data);
  //     console.log("deletedPaymentIds >>> ", deletedPaymentIds);
  //     console.log("amountIsConverted >>> ", amountIsConverted);
  //     console.log("convertedTotal >>> ", convertedTotal);
  //   }
  // }, [
  //   showEditPayModal,
  //   data,
  //   deletedPaymentIds,
  //   amountIsConverted,
  //   convertedTotal,
  //   modalReset,
  // ]);

  const COLUMNS = [
    {
      Header: "Payment Date",
      accessor: "paidOrFailedAt",
      isEditable: true,
      type: "date",
    },
    { Header: "Payment Amount", accessor: "amountPaid" },
    {
      Header: "Currency",
      accessor: "currency",
      type: "currency",
      fixed: false,
      isEdit: true,
    },
    {
      Header: "Delete",
      id: "delete",
      fixed: true,
      accessor: (str: any) => "delete",

      Cell: (tableProps: any) => (
        <span
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          // onClick={() => handleDelete(tableProps)}
        >
          Delete
        </span>
      ),
    },
  ];

  const { getRecords } = useAuth();
  const [columns, setColumns] = useState<any>(COLUMNS);

  const handleUpdateStep = (value: string) => {
    setStep(value);
  };

  useEffect(() => {
    // console.log("****** YOU ARE IN EDIT MODAL ******");
    const _columns = cloneDeep(columns);

    // setData([
    //   {
    //     paidOrFailedAt: "",
    //     amountPaid: "",
    //     currency: selectedBill?.currency,
    //   },
    // ]);
    _columns[0].maxDate = new Date();
    setColumns(_columns);
  }, []);

  useEffect(() => {
    if (showEditPayModal) {
      if (!selectedBill || !selectedBill?.linkedTransactions?.length) {
        return;
      }

      if (selectedBill?.linkedTransactions?.length === 0) {
        setData([]);
      }

      const transactions = selectedBill.linkedTransactions;
      const transactionsList: any = [];
      for (let transaction of transactions) {
        transactionsList.push({
          id: transaction.id,
          currency: transaction.currency,
          amountPaid: transaction.amountPaid,
          paidOrFailedAt: moment(transaction.paidOrFailedAt).format(
            "YYYY-MM-DD"
          ),
        });
      }
      setData(transactionsList);
    }
  }, [showEditPayModal, selectedBill, step]);

  const handleSubmit = (record?: any) => {
    handleOnClose();
    if (record && setSelectedRecord) {
      let updatedRec = {
        name: record.name,
        amountPaid: "0",
        amountDue: record.amount.toString(),
        amountTotal: record.amount.toString(),
        dueDate: moment(record.dueDate).format("YYYY-MM-DD"),
        issueDate: moment(record.issueDate).format("YYYY-MM-DD"),
        invoiceNumber: record.referenceNumber,
        description: record.description,
        cashflowBucket: record.selectedCashflowBucket,
      };
      let newRec = Object.assign(selectedBill, updatedRec);
      setSelectedRecord(newRec);
    }
  };

  const handleOnBack = () => {
    if (step !== InvoiceStep.INVOICE) {
      setStep(InvoiceStep.INVOICE);
    } else {
      setShowEditPayModal(false);
      setData([]);
      setStep(InvoiceStep.INVOICE);
    }
  };

  const handleOnClose = () => {
    setShowEditPayModal(false);
    setData([]);
    setStep(InvoiceStep.INVOICE);
  };

  const addMorePayments = () => {
    setData([
      ...data,
      {
        paidOrFailedAt: new Date(),
        amountPaid: "",
        currency: selectedBill?.currency,
      },
    ]);
  };

  const validateCurrencyInData = (data: any[]) => {
    return data.every(
      (item) => typeof item.currency === "string" && item.currency.trim() !== ""
    );
  };

  const handleConvertData = async () => {
    let totalAmount = 0;
    const selectedBillTotal = Number(selectedBill.amountTotal);

    if (!validateCurrencyInData(data)) {
      setModalState({
        open: true,
        title: "Invalid Currency!",
        message: "Currency values should not be empty.",
      });
      return;
    }

    for (let obj of data as any) {
      const amountPaid = Number(obj.amountPaid);
      if (!amountPaid || amountPaid === 0) {
        setModalState({
          open: true,
          title: "Invalid Values!",
          message: "Payment amount should not be empty or zero.",
        });
        return; // This will exit the handleSavePayment function entirely
      }

      // totalAmount += amountPaid;
    }

    const filteredData = data.filter(
      (item) => item.amountPaid !== "" && item.paidOrFailedAt !== ""
    );

    try {
      const response = await verifyTotalAmount(
        filteredData,
        selectedBillTotal,
        selectedBill.currency
      );

      if (response.data.success) {
        if (
          Number(response.data.response.convertedTotal?.toFixed(2)) >
          selectedBillTotal
        ) {
          setModalState({
            open: true,
            title: "Payment Amount Is Invalid!",
            message:
              "Total payment amount after conversion should not be greater than bill amount.",
          });
          setConvertedTotal(Number(response.data.response.convertedTotal));
          return;
        }
        setConvertedTotal(Number(response.data.response.convertedTotal));
      }
    } catch (error) {
      console.error("Error verifying total amount: ", error);
      setModalState({
        open: true,
        title: "Payment Submission Failed!",
        message: "Missing payment details.",
      });

      return;
    }
  };

  const handleSaveData = async () => {
    let totalAmount = 0;
    const selectedBillTotal = Number(selectedBill.amountTotal);

    if (!validateCurrencyInData(data)) {
      setModalState({
        open: true,
        title: "Invalid Currency!",
        message: "Currency values should not be empty.",
      });
      return;
    }

    for (let obj of data as any) {
      const amountPaid = Number(obj.amountPaid);
      if (!amountPaid || amountPaid === 0) {
        setModalState({
          open: true,
          title: "Invalid Values!",
          message: "Payment amount should not be empty or zero.",
        });
        return; // This will exit the handleSavePayment function entirely
      }

      // totalAmount += amountPaid;
    }

    const filteredData = data.filter(
      (item) => item.amountPaid !== "" && item.paidOrFailedAt !== ""
    );

    try {
      const response = await verifyTotalAmount(
        filteredData,
        selectedBillTotal,
        selectedBill.currency
      );
      if (response.data.success) {
        totalAmount = Number(response.data.response.convertedTotal?.toFixed(2));
        if (
          Number(response.data.response.convertedTotal?.toFixed(2)) >
          selectedBillTotal
        ) {
          setModalState({
            open: true,
            title: "Payment Amount Is Invalid!",
            message:
              "Total payment amount after conversion should not be greater than bill amount.",
          });

          return;
        }
      }
    } catch (error) {
      console.error("Error verifying total amount: ", error);
      setModalState({
        open: true,
        title: "Payment Submission Failed!",
        message: "Missing payment details.",
      });

      return;
    }

    // console.log("selectedBillTotal >>>>> ", selectedBillTotal);
    // console.log("totalAmount >>>>> ", totalAmount);

    let fieldsToUpdate = {};
    let amountDue = selectedBillTotal - totalAmount;

    if (totalAmount === 0) {
      fieldsToUpdate = {
        status: InvoiceStatus.Unpaid,
        amountDue: String(amountDue),
      };
    } else if (totalAmount < selectedBillTotal) {
      fieldsToUpdate = {
        status: InvoiceStatus.PartiallyPaid,
        amountDue: String(amountDue),
      };
    } else if (totalAmount === selectedBillTotal) {
      fieldsToUpdate = {
        status: InvoiceStatus.Paid,
      };
    }

    setShowEditPayModal(false);
    setData([]);

    // Assuming you want to update the invoice once after all calculations
    await updateInvoice(
      fieldsToUpdate,
      selectedBill.id,
      filteredData,
      deletedPaymentIds
    );
    getRecords(undefined, 0, selectedBill.type, "dueDate", "DESC", {});

    setDeletedPaymentIds([]);
  };

  const handleDeletePayment = (index: any) => {
    const paymentId = data[index].id;

    if (paymentId || paymentId === 0) {
      // This checks for all falsy values except 0
      setDeletedPaymentIds((prevIds) => [...prevIds, paymentId]);
    } else {
      console.log("Invalid payment ID encountered:", paymentId);
    }

    setData((prevData) => {
      return prevData.filter((_, i) => i !== index);
    });
  };

  const updateFieldInData = (index: any, field: any, value: any) => {
    setData((prevData) =>
      prevData.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };

  return (
    <>
      <CustomDrawer
        zIndex={1000}
        opened={showEditPayModal}
        onBack={handleOnBack}
        onClose={handleOnClose}
        title={title}
      >
        {step === InvoiceStep.EDIT_PAYMENT ? (
          <Container>
            <Card className="add-record-card-container" isNormal noShadow>
              <CardTitle>Edit Payments</CardTitle>
              <div className="flex-direction-column payment-contact-container ">
                <div className="payment-contact">{selectedBill.name}</div>
                <br />
                <div className="flex-direction-row-space-between">
                  <div className="flex-direction-column">
                    <div className="payment-contact-key">Due Date</div>
                    <div className="payment-contact-value">
                      {moment(selectedBill.dueDate).format("YYYY-MM-DD")}
                    </div>
                  </div>
                  <div className="flex-direction-column">
                    <div className="payment-contact-key">Total Amount</div>
                    <div className="payment-contact-value">
                      {selectedBill?.currency} {selectedBill.amountTotal}
                    </div>
                  </div>
                </div>
              </div>

              {/* <Styles> */}
              {data?.length ? (
                <>
                  {/* <Table
                selectedBillCurrencyEdit={selectedBill?.currency}
                columns={columns}
                data={data}
                updateMyData={updateMyData}
                onDelete={(tableProps: any) =>
                  handleDelete(tableProps, selectedBill)
                }
              /> */}
                  <AddMorePayments
                    billCurrency={selectedBill?.currency}
                    convertedTotal={convertedTotal}
                    setAmountIsConverted={setAmountIsConverted}
                    data={data}
                    handleDeletePayment={handleDeletePayment}
                    updateFieldInData={updateFieldInData}
                  />
                </>
              ) : (
                <></>
              )}
              {/* </Styles> */}
              <div style={{ display: "flex" }}>
                {modalState && (
                  <>
                    <AlertModal
                      modalOpened={modalState.open}
                      resetModal={modalReset}
                      title={modalState.title}
                      message={modalState.message}
                    />
                  </>
                )}
                {/* {amountIsConverted ? ( */}
                <button
                  disabled={
                    !data.length
                    // ||
                    // data.some((datum) => {
                    //   return !datum.paidOrFailedAt || !datum.amountPaid;
                    // })
                  }
                  onClick={handleSaveData}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "100%",
                  }}
                  className="button mt-4 is-bold "
                >
                  Save
                </button>
                {/* ) : ( */}
                <button
                  disabled={!data.length}
                  onClick={handleConvertData}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "100%",
                  }}
                  className="button mt-4 is-bold "
                >
                  Calculate Total
                </button>
                {/* )} */}

                <button
                  onClick={addMorePayments}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "100%",
                  }}
                  className="button mt-4 is-bold "
                >
                  Add Payment
                </button>
              </div>
            </Card>
          </Container>
        ) : (
          <AddRecord
            recordType={invoiceType}
            onSubmit={handleSubmit}
            selectedBill={selectedBill}
            handleUpdateStep={handleUpdateStep}
          />
        )}
      </CustomDrawer>
    </>
  );
}

export default EditModal;

// Be sure to pass our updateMyData and the skipPageReset option

const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
    margin-left: 6px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Styles = styled.div`
  padding: 0.2rem;

  .table-payments table {
    border-spacing: 0;
    border: 1px solid black;

    .table-payments tr {
      border: 1px solid black;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    .table-payments th {
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
    .table-payments td {
      margin: 0;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0.5rem;
        margin: 0;
        border: 0;
      }

      span {
        padding: 0.5rem;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState, useEffect } from "react";
import { Select, Button, LoadingOverlay, Checkbox } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { CustomDrawer } from "../../../../../common/CustomDrawer";
import Card from "../../../../../common/Card/Card";
import styled from "styled-components";
import { COLORS } from "../../../../../constants/theme";
import moment from "moment";
import { InvoiceTypes } from "../../../../../constants/globalConstants";
import axios from "axios";
import _ from "lodash";
import { useAuth } from "../../../../../contexts";
import AlertModal from "../../../../../common/AlertModal/AlertModal";
import { getTimezoneSettledDate } from "../../../../../modules/getTimezoneSettledDate";
import { configs } from "../../../../../constants";
import { showNotification } from "@mantine/notifications";
import AddEditForecastBudgetModal from "../../../../Cashflow/components/ForecastBudget/AddEditForecastBudgetModal";
import EditEstimateConfigForBulk from "../../../../Cashflow/components/ForecastBudget/EditEstimateConfigForBulk";
import { getAllForecasts } from "../../../../../repositories/financialProjection";
import { formatCashflowMappingInInvoices } from "../../../../../modules/formatCashflowMapping";
import formatEstimates from "../../../../../modules/formatEstimates";

function EditEstimatesInBulk({
  selectedKeys,
  fetchForecasts,
  editInBulk,
  setEditInBulk,
  records,
  setRecords,
  setEditRecordState,
  title,
  deleteRecords,
  cashflowType,
}: any) {
  const {
    forecasts,
    records: _records,
    externalAccounts: _externalAccounts,
  } = useAuth();

  const [formValues, setFormValues] = useState<any>({});

  const [loading, setLoading] = useState(true);

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    message: "",
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [editState, setEditState] = useState(false);
  const [selectedBill, setSelectedBill] = useState({});
  const [estimateIds, setEstimateIds] = useState([]);
  const [groupedEstimates, setGroupedEstimates] = useState({});

  const [selectedBillsArr, setSelectedBillsArr] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const initalValues = records.reduce(
      (acc: any, bill: any) => ({
        ...acc,
        [bill.id]: {
          isDelete: false,
          name: bill.name,
          amountTotal: bill.amount,
          currency: bill.currency,
          currentEstDate: bill.dueDate,
          minDate: bill?.settings?.startForecastDate,
          maxDate: bill?.settings?.endForecastDate,
          settingsId: bill?.settings?.id,
        },
      }),
      {}
    );

    const groupedRecords = records.reduce((acc: any, bill: any) => {
      if (!bill.settings?.id) return acc;
      acc[bill.settings.id] = acc[bill.settings.id] || [];
      acc[bill.settings.id].push(bill);
      return acc;
    }, {});

    setGroupedEstimates(groupedRecords);

    if (isMounted) {
      setFormValues(initalValues);
    }

    return () => {
      isMounted = false;
    };
  }, [records]);

  const handleOnBack = () => {
    setEditRecordState([]);
    setEditInBulk(false);
  };

  const handleOnClose = () => {
    setEditRecordState([]);
    setEditInBulk(false);
  };

  const handleChange = (billId: any, field: any, value: any) => {
    setFormValues((prev: any) => ({
      ...formValues,
      [billId]: {
        ...prev[billId],
        [field]: value,
      },
    }));
  };

  const onEdit = (billId: any, billSettingId: any) => {
    const estIds: any = [];
    records.forEach((record: any) => {
      estIds.push(record.id);
    });
    setEstimateIds(estIds);

    const forecast = records.find((record: any) => record.id == billId);

    const forecastAll = records.filter(
      (record: any) => record.settings.id === billSettingId
    );

    setSelectedBillsArr(forecastAll);
    setSelectedBill(forecast);
    setEditState(true);
  };

  const onSave = async () => {
    try {
      setLoading(true);

      let errors: any = [];
      let deleteRows: any = [];

      const formKeys = Object.keys(formValues);

      formKeys.forEach((key, index) => {
        const item = formValues[key];

        const currEstDate = new Date(item.currentEstDate);
        const minDate = new Date(item.minDate);
        const maxDate = new Date(item.maxDate);

        if (currEstDate > maxDate && !item.isDelete) {
          errors.push(
            `Row ${
              index + 1
            }: Current estimate date must be less than the estimate setting max date.`
          );
        }

        if (currEstDate < minDate && !item.isDelete) {
          errors.push(
            `Row ${
              index + 1
            }: Current estimate date must be greater than the estimate setting min date.`
          );
        }

        if (item.isDelete) {
          deleteRows.push(
            `Are you sure you want to delete estimate record at row no. ${
              index + 1
            }?`
          );
        }
      });

      if (errors.length > 0) {
        setModalState({
          open: true,
          title: "Invalid estimate date!",
          message: errors.join("\n"),
        });
        return;
      }

      if (deleteRows.length > 0) {
        setDeleteModal(true);
        setModalState({
          open: true,
          title: "Warning!",
          message: "Are you sure you want to delete the selected record(s)?",
        });
        return;
      }

      const editEstArray = Object.keys(formValues).map((key, index) => {
        const item = formValues[key];
        const estDate = getTimezoneSettledDate(new Date(item.currentEstDate));

        return {
          estimateId: key,
          currentEstDate: estDate,
          ...item,
        };
      });

      const response = await axios.patch(
        configs.urls.BASE_URL + `/financial_projection/updateInBulk`,
        {
          editEstArray,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        showNotification({
          color: "teal",
          message: "Estimate records updated successfully",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
        fetchForecasts();
        handleOnClose();
      }
    } catch (err) {
      console.log("Error in bulk saving: ", err);
    } finally {
      setLoading(false);
      setDeleteModal(false);
    }
  };

  const modalReset = () => {
    setModalState({
      open: false,
      title: "",
      message: "",
    });
  };

  const confirmDeleteSave = async () => {
    modalReset();
    const editEstArray = Object.keys(formValues).map((key, index) => {
      const item = formValues[key];
      const estDate = getTimezoneSettledDate(new Date(item.currentEstDate));
      return {
        estimateId: key,
        currentEstDate: estDate,
        ...item,
      };
    });

    try {
      const response = await axios.patch(
        configs.urls.BASE_URL + `/financial_projection/updateInBulk`,
        { editEstArray },
        { withCredentials: true }
      );
      if (response.status === 200) {
        showNotification({
          color: "teal",
          message: "Estimate records updated successfully",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
        fetchForecasts();
        handleOnClose();
      }
    } catch (err) {
      console.error("Error in bulk saving:", err);
    } finally {
      setLoading(false);
    }
  };

  const updateEstimateRecords = async () => {
    setLoading(true);

    const { data, success } = await getAllForecasts(
      undefined,
      undefined,
      undefined
    );

    if (success) {
      const formattedRecords = formatCashflowMappingInInvoices(data);
      let forecasts: any[] = formatEstimates(formattedRecords);

      let recordsToDisplay = forecasts.filter(
        (forecast: any) => forecast.settings.type === cashflowType
      );

      if (recordsToDisplay) {
        const selectedKeysAsIntegers = selectedKeys.map((key: any) =>
          parseInt(key)
        );
        const selectedRecords = recordsToDisplay.filter((record: any) =>
          selectedKeysAsIntegers.includes(record.id)
        );

        const initalValues = selectedRecords.reduce(
          (acc: any, bill: any) => ({
            ...acc,
            [bill.id]: {
              isDelete: false,
              name: bill.name,
              amountTotal: bill.amount,
              currency: bill.currency,
              currentEstDate: bill.dueDate,
              minDate: bill?.settings?.startForecastDate,
              maxDate: bill?.settings?.endForecastDate,
              settingsId: bill?.settings?.id,
            },
          }),
          {}
        );

        const groupedRecords = records.reduce((acc: any, bill: any) => {
          if (!bill.settings?.id) return acc;
          acc[bill.settings.id] = acc[bill.settings.id] || [];
          acc[bill.settings.id].push(bill);
          return acc;
        }, {});

        setGroupedEstimates(groupedRecords);

        setFormValues(initalValues);
      }
    }

    setLoading(false);
  };

  return (
    <CustomDrawer
      opened={editInBulk}
      onBack={handleOnBack}
      onClose={handleOnClose}
      title={title}
    >
      <Container>
        <Fragment>
          <div
            style={{
              position: "relative",
              width: "80%",
            }}
          >
            <div className="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"></div>
            <LoadingOverlay
              visible={loading}
              loaderProps={{
                color: COLORS.greenBlue,
                variant: "oval",
              }}
            />
            <Card
              className="table-container mb-3"
              style={{
                position: "relative",
              }}
            >
              <div
                className="table-container custom-scrollbar mb-3"
                style={{
                  height: "calc(100vh - 280px)",
                  overflowY: "scroll",
                }}
              >
                <table
                  style={{ borderTop: "1px solid #e0e0e0" }}
                  className="table is-bordered is-striped is-hoverable is-fullwidth is-relative "
                >
                  <thead>
                    <tr
                      style={{
                        fontWeight: "700",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                        zIndex: 9,
                      }}
                    >
                      <TableHeader>Delete</TableHeader>
                      <TableHeader>Estimate Name</TableHeader>
                      <TableHeader>Total Amount</TableHeader>
                      <TableHeader>Current Estimate Date</TableHeader>
                      <TableHeader>Edit Estimate Settings</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(groupedEstimates).map(
                      ([settingsId, bills]: any) => {
                        return (
                          <Fragment key={settingsId}>
                            {bills.map((bill: any, index: number) => (
                              <tr
                                key={bill.id}
                                style={{
                                  borderTop:
                                    index === 0 ? `4px solid #dbdbdb` : "none",
                                }}
                              >
                                <TableData className="p-3">
                                  <Checkbox
                                    style={{ zIndex: 0 }}
                                    checked={formValues[bill.id]?.isDelete}
                                    onChange={(e) =>
                                      handleChange(
                                        bill.id,
                                        "isDelete",
                                        e.currentTarget.checked
                                      )
                                    }
                                  />
                                </TableData>
                                <TableData className="p-3">
                                  <Input
                                    disabledText={formValues[bill.id]?.isDelete}
                                    readOnly={true}
                                    type="text"
                                    onFocus={(event) => event.target.select()}
                                    name="name"
                                    value={formValues[bill.id]?.name}
                                    onChange={(e) =>
                                      setFormValues((prev: any) => ({
                                        ...formValues,
                                        [bill.id]: {
                                          ...prev[bill.id],
                                          name: e.target.value,
                                        },
                                      }))
                                    }
                                  />
                                </TableData>
                                <TableData>
                                  <InputContainer
                                    style={{ position: "relative" }}
                                  >
                                    <Input
                                      disabledText={
                                        formValues[bill.id]?.isDelete
                                      }
                                      disabled={formValues[bill.id]?.isDelete}
                                      type="text"
                                      onFocus={(event) => event.target.select()}
                                      name="amountTotal"
                                      value={formValues[bill.id]?.amountTotal}
                                      pattern="\d*"
                                      maxLength={9}
                                      onChange={(e) =>
                                        handleChange(
                                          bill.id,
                                          "amountTotal",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <Currency>
                                      {formValues[bill.id]?.currency || "AED"}
                                    </Currency>
                                  </InputContainer>
                                </TableData>
                                <TableData className="p-3">
                                  <CustomDatePicker
                                    disabled={formValues[bill.id]?.isDelete}
                                    minDate={
                                      new Date(formValues[bill.id]?.minDate)
                                    }
                                    maxDate={
                                      new Date(formValues[bill.id]?.maxDate)
                                    }
                                    clearable={false}
                                    styles={{
                                      input: {
                                        minWidth: "130px !important",
                                        border: "none",
                                        fontWeight: "bolder",
                                        fontSize: "14px !important",
                                      },
                                    }}
                                    value={
                                      new Date(
                                        formValues[bill.id]?.currentEstDate
                                      )
                                    }
                                    onChange={(date: any) =>
                                      handleChange(
                                        bill.id,
                                        "currentEstDate",
                                        moment(date).format("YYYY-MM-DD")
                                      )
                                    }
                                  />
                                </TableData>
                                {index === 0 && (
                                  <TableDataV2
                                    align="center"
                                    valign="middle"
                                    rowSpan={bills.length.toString()}
                                  >
                                    <button
                                      disabled={loading}
                                      className={`button mv-6 is-bold is-small ${
                                        loading ? "is-loading" : ""
                                      }`}
                                      style={{
                                        backgroundColor: COLORS.greenBlue,
                                        color: COLORS.white,
                                        width: "4rem",
                                        // marginTop: `${bills.length * 10}px`,
                                      }}
                                      onClick={() =>
                                        onEdit(bill.id, bill.settings.id)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </TableDataV2>
                                )}
                              </tr>
                            ))}
                          </Fragment>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              disabled={loading}
              className={`button mv-6 is-bold is-small ${
                loading ? "is-loading" : ""
              }`}
              style={{
                backgroundColor: COLORS.greenBlue,
                color: COLORS.white,
                width: "15rem",
              }}
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </Fragment>
        {modalState && (
          <>
            <AlertModal
              isDelete={deleteModal}
              modalOpened={modalState.open}
              confirmDelete={confirmDeleteSave}
              resetModal={modalReset}
              title={modalState.title}
              message={modalState.message}
            />
          </>
        )}

        <EditEstimateConfigForBulk
          fromEstimate={true}
          opened={editState}
          onClose={() => {
            fetchForecasts();
            updateEstimateRecords();
            setEditState(false);
          }}
          type="forecast"
          isDetailsOnly={false}
          data={selectedBill}
          selectedBillsArr={selectedBillsArr}
          section={cashflowType}
          editForecast={true}
        />
      </Container>
    </CustomDrawer>
  );
}

export default EditEstimatesInBulk;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100vw;
`;

const TableData = styled.td`
  font-size: 14px;
  text-align: left;
  /* :sec-child {
    min-width: 120px;
  } */
`;

const TableDataV2 = styled.td`
  vertical-align: middle !important;
  text-align: center !important;
`;

const TableHeader = styled.th`
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.brownGrey};
  text-align: left;
  height: 20px;
  vertical-align: middle;
  /* :first-child {
    min-width: 120px;
  } */
`;

const Input = styled.input<{ disabledText: any }>`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.disabledText && "#909296 !important"};

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #707070;

  .mantine-DatePicker-dropdown {
    width: 455px !important;
  }
`;

const Currency = styled.div`
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  /* align right */
  right: 0;
  top: 25%;

  user-select: none;

  background-color: #fff;
`;

export const InputContainer = styled.div`
  position: relative;
  margin: 5px;
  flex: 1;
`;

import React, { Fragment, useEffect, useState } from "react";
import { CustomDrawer } from "../../../../common/CustomDrawer";
import styled from "styled-components";
import {
  CardTitle,
  Input,
  InputContainer,
  InputLabel,
} from "../../../Payments/styles";
import Card from "../../../../common/Card/Card";
import { Select } from "@mantine/core";
import { SelectStyles } from "../../../../constants/styledComponents";
import { CustomDatePicker } from "../../../Payments/components/AddRecord";
import { COLORS } from "../../../../constants/theme";
import { saveForecastsNBudget } from "../../repository/forecasts";
import { notify } from "../../../../modules/showNotification";
import moment from "moment";
import { InvoiceTypes } from "../../../../constants/globalConstants";
import { filterContacts } from "../../../../repositories/contactRepository";
import { useAuth } from "../../../../contexts";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { configs } from "../../../../constants";
import { MapCategory } from "../../../Contacts/components/MapCategory";
import { If } from "../../../../common/Utils/If";
import { getTimezoneSettledDate } from "../../../../modules/getTimezoneSettledDate";
import currencies from "../../../../assets/files/currencies.json";
import { IMAGES } from "../../../../constants";
import CardButton from "../../../../common/CardButton/CardButton";
import { Button } from "rsuite";
import UploadEstimatesCsvBulk from "./UploadEstimatesCsvBulk";

type Props = {
  fromEstimate?: boolean;
  opened: boolean;
  onClose: () => void;
  type: "forecast" | "budget";
  section: "Payable" | "Receivable";
  isDetailsOnly?: boolean;
  data?: any;
  editForecast?: boolean;
};

enum HowOftenDoesThisOccur {
  Weekly = "Repeats every week",
  Monthly = "Repeats every month",
  Once = "Does Not Repeat",
}

enum DoesAmountChange {
  Yes = "Yes",
  No = "No, it's fixed",
}

type Data = {
  name: string;
  id?: string | undefined;
  amount: number | undefined;
  startDate: Date;
  howOftenDoesThisOccur: HowOftenDoesThisOccur;
  hasEndDate: boolean;
  endDate?: Date;
  doesAmountChange: DoesAmountChange;
  cashflowCategory: string;
  budgetCategory?: string;
  currency: string;
};

const INITIAL_DATA: Data = {
  name: "",
  amount: undefined,
  startDate: new Date(),
  howOftenDoesThisOccur: HowOftenDoesThisOccur.Monthly,
  hasEndDate: false,
  // end date should be last day of the year
  endDate: moment(new Date()).endOf("year").toDate(),
  doesAmountChange: DoesAmountChange.No,
  cashflowCategory: "",
  currency: "AED",
};

function AddEditForecastBudgetModal({
  fromEstimate,
  opened,
  onClose,
  type,
  isDetailsOnly,
  data: _data,
  section,
  editForecast,
}: Props) {
  const {
    externalAccounts: _externalAccounts,
    fetchForecasts,
    cashflowBuckets,
    fetchExternalAccounts,
    selectedScenario,
  } = useAuth();

  // console.log("Data >>> ", _data);

  const [step, setStep] = useState<any>(0);

  const [estSettingStartDate, setEstSettingStartDate] = useState<
    Date | undefined
  >(undefined);
  const [estSettingEndDate, setEstSettingEndDate] = useState<Date | undefined>(
    undefined
  );

  let recordType = section;

  let recordTypeName = recordType === InvoiceTypes.Payable ? "Bill" : "Invoice";
  let contactType = recordType === InvoiceTypes.Payable ? "Vendor" : "Customer";

  const [selectedName, setSelectedName] = useState("");

  const [options, setOptions] = useState<any>([]);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [data, setData] = React.useState<Data>(INITIAL_DATA);

  const [cashflowBucketOptions, setCashflowBucketOptions] = useState<any>([]);

  const [contact, setContact] = useState<any>();

  useEffect(() => {
    if (!selectedName || selectedName === "") {
      setContact(undefined);
      return;
    }

    const contact = _externalAccounts.find(
      (account: any) => account.displayName === selectedName
    );
    if (contact) {
      setContact(contact);
    }
  }, [selectedName, _externalAccounts]);

  useEffect(() => {
    setCashflowBucketOptions(
      cashflowBuckets.map((bucket: any) => {
        return {
          value: bucket.value,
          label: bucket.value,
          disabled: !(bucket.name === section),
        };
      })
    );
  }, [cashflowBuckets, section]);

  useEffect(() => {
    if (_data) {
      setData({
        ...INITIAL_DATA,
        ..._data,
        startDate: isDetailsOnly
          ? new Date(_data?.dueDate)
          : new Date(_data?.settings?.startForecastDate),
        endDate: new Date(_data?.settings?.endForecastDate),
        forecastConfigId: _data?.settings?.id,
        cashflowCategory: _data?.settings?.cashflowCategory,
        currency: _data?.currency,
      });
      setSelectedName(_data?.name || "");
      setEstSettingStartDate(
        _data?.settings?.startForecastDate
          ? new Date(_data.settings.startForecastDate)
          : undefined
      );
      setEstSettingEndDate(
        _data?.settings?.endForecastDate
          ? new Date(_data.settings.endForecastDate)
          : undefined
      );
    } else {
      let firstBucket = cashflowBucketOptions.filter(
        (bucket: any) => !bucket.disabled
      );
      setData({
        ...INITIAL_DATA,
        cashflowCategory: firstBucket[0]?.value,
      });
    }
  }, [_data, isDetailsOnly, cashflowBucketOptions]);

  useEffect(() => {
    setOptions(
      _externalAccounts.map((account: any) => ({
        value: account.displayName,
        label: account.displayName,
        disabled:
          recordTypeName === "Bill"
            ? !account.types.includes(contactType) &&
              !account.types.includes("Employee")
            : !account.types.includes(contactType) ||
              account.types.includes("Employee"),
      }))
    );
  }, [_externalAccounts, section]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const filteredContacts = await filterContacts(selectedName);

      setOptions(
        _.uniqBy(filteredContacts, "id").map((account: any) => ({
          value: account.displayName,
          label: account.displayName,
          disabled:
            !account.types.includes(contactType) &&
            !account.types.includes("Employee"),
        }))
      );
    }, 0);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedName]);

  const save = async () => {
    if (!data.name || data.name === "") {
      return showNotification({
        title: "Please enter a name",
        message: "Name is required",
        color: "red",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }

    if (!data.amount || data.amount == 0) {
      return showNotification({
        title: "Please enter an amount",
        message: "Amount is required",
        color: "red",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }

    setLoading(true);

    const { success, data: response } = await saveForecastsNBudget({
      ...data,
      type: type === "forecast" ? "forecast" : "budget",
      onlyDetails: isDetailsOnly,
      section: section,
    });

    notify({
      success: success && "Estimate settings saved successfully",
      error: !success && "Error while saving forecast settings",
      onSuccess: () => {
        fetchForecasts();
        onClose();
      },
    });
    setLoading(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const decideTitle = () => {
    if (type === "forecast") {
      if (Array.isArray(_data)) {
        return _data.length > 0 ? "Edit Estimates" : "Upload Estimates";
      } else if (typeof _data === "object" && _data !== null) {
        return Object.keys(_data).length > 0
          ? "Edit Estimate"
          : "Upload Estimates";
      }
    } else {
      if (Array.isArray(_data)) {
        return _data.length > 0 ? "Edit budget" : "Add budget";
      } else if (typeof _data === "object" && _data !== null) {
        return Object.keys(_data).length > 0 ? "Edit budget" : "Add budget";
      }
    }
  };

  const saveContact = (name: string) => {
    setSelectedName("");
    axios
      .post(
        configs.urls.BASE_URL + "/contact/create",
        {
          name: name,
          displayName: name,
          type: contactType,
          createDestinationWithLean: false,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          showNotification({
            color: "teal",
            message: "Contact was saved!",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          fetchExternalAccounts();
          setSelectedName(name);
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    if (editForecast) {
      setStep(2);
    }
  }, [editForecast, step]);

  return (
    <CustomDrawer
      zIndex={9999}
      containerStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      opened={opened}
      onClose={() => {
        setStep(0);
        onClose();
      }}
      onBack={() => {
        if (fromEstimate) {
          onClose();
        } else {
          if (step > 0) {
            setStep(0);
          } else {
            setStep(0);
            onClose();
          }
        }
      }}
      title={decideTitle()}
    >
      {step === 0 ? (
        <Container>
          <CardButton
            cardStyles={{
              width: "178%",
              padding: "12px",
            }}
            icon={IMAGES.bill}
            title="Estimate details"
            description="Create estimates from TSC directly"
            onClick={() => {
              setStep(2);
            }}
          />

          <Separator>OR</Separator>

          <CardButton
            cardStyles={{
              width: "178%",
              padding: "12px",
            }}
            icon={IMAGES.upload_bill}
            title="Upload a csv"
            description="Quickly upload estimates into TSC"
            onClick={() => {
              setStep(1);
            }}
          />

          <Button
            onClick={() => {
              setStep(0);
              onClose();
            }}
            appearance="link"
            className="mt-3"
            style={{ color: "black" }}
          >
            Cancel
          </Button>
        </Container>
      ) : step === 1 ? (
        <Card
          className="add-record-card-container"
          style={{
            background: "#fff",
            width: "auto",
          }}
          isNormal
          noShadow
        >
          <UploadEstimatesCsvBulk
            isBill={recordTypeName === "Bill" ? true : false}
            setStep={setStep}
            onClose={onClose}
            fetchForecasts={fetchForecasts}
          />
        </Card>
      ) : (
        <Form>
          <Card
            className="add-record-card-container"
            style={{
              background: "#fff",
              width: "40%",
            }}
            isNormal
            noShadow
          >
            <CardTitle>
              Estimate {isDetailsOnly ? "Details" : "Settings"}
            </CardTitle>

            {isDetailsOnly ? (
              <Fragment>
                {/* <InputContainer>
                <InputLabel>Name</InputLabel>
                <Input
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  readOnly={_data ? true : false}
                />
              </InputContainer> */}

                <InputContainer style={{ position: "relative" }}>
                  <InputLabel>Estimate amount</InputLabel>
                  <Input
                    name="amount"
                    type="number"
                    value={data.amount}
                    onChange={handleChange}
                  />
                  <Currency>{data?.currency || "AED"}</Currency>
                </InputContainer>

                <InputContainer>
                  <InputLabel>Current estimate date</InputLabel>
                  <CustomDatePicker
                    clearable={false}
                    styles={{
                      input: {
                        border: "none",
                        fontSize: "1.5rem",
                        padding: "0.2rem 0",
                      },
                    }}
                    value={data.startDate}
                    minDate={estSettingStartDate}
                    maxDate={estSettingEndDate}
                    onChange={(date: Date) => {
                      const newDate = getTimezoneSettledDate(date);

                      setData((prev: any) => ({
                        ...prev,
                        startDate: newDate,
                      }));
                    }}
                  />
                </InputContainer>
              </Fragment>
            ) : (
              <Fragment>
                <InputContainer>
                  <InputLabel>Contact</InputLabel>
                  <Select
                    name="selectedName"
                    value={selectedName}
                    data={options}
                    nothingFound={
                      selectedName === ""
                        ? "Type to search contacts"
                        : "Nothing found"
                    }
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setSelectedName(query);
                      setOptions([...options, { value: query, label: query }]);
                      setData((prev: any) => ({
                        ...prev,
                        name: query,
                      }));
                      saveContact(query);
                      return item;
                    }}
                    onChange={(value: string) => {
                      setSelectedName(value);
                      setData((prev: any) => ({
                        ...prev,
                        name: value,
                      }));
                    }}
                    onSearchChange={(query: string) => {
                      setSelectedName(query);
                    }}
                    filter={(value, item) =>
                      item.value
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                    styles={{
                      ...SelectStyles,
                      input: {
                        ...SelectStyles.input,
                        fontWeight: 400,
                      },
                    }}
                  />
                </InputContainer>

                {/* <InputContainer>
                <InputLabel>Name</InputLabel>
                <Input
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  readOnly={_data ? true : false}
                />
              </InputContainer> */}

                <InputContainer style={{ position: "relative" }}>
                  <InputLabel>Estimate amount</InputLabel>
                  <Input
                    name="amount"
                    type="number"
                    value={data.amount}
                    onChange={handleChange}
                  />
                  {/* <Currency>AED</Currency> */}
                </InputContainer>

                <InputContainer>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    defaultValue={"AED"}
                    value={data.currency}
                    onChange={(value: string) => {
                      setData({ ...data, currency: value });
                    }}
                    styles={{
                      input: {
                        marginTop: 4,
                        fontWeight: "bold",
                        fontSize: 18,
                      },
                    }}
                    data={currencies}
                    searchable
                    maxDropdownHeight={400}
                    nothingFound="Nothing here"
                    filter={(value, item: any) =>
                      item.label
                        .toLowerCase()
                        .includes(value.toLowerCase().trim()) ||
                      item.value
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Starting estimate date</InputLabel>
                  <CustomDatePicker
                    clearable={false}
                    styles={{
                      input: {
                        border: "none",
                        fontSize: "1.5rem",
                        padding: "0.2rem 0",
                      },
                    }}
                    value={data.startDate}
                    onChange={(date: Date) => {
                      let offset = date.getTimezoneOffset();
                      let offsetInHours = offset / 60;
                      let offsetInMilliseconds = offsetInHours * 60 * 60 * 1000;
                      let newDate = new Date(
                        date.getTime() - offsetInMilliseconds
                      );

                      setData((prev: any) => ({
                        ...prev,
                        startDate: moment(newDate).toDate().toISOString(),
                      }));
                    }}
                    maxDate={data.endDate}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>How often does this occur?</InputLabel>
                  <Select
                    data={[
                      {
                        label: HowOftenDoesThisOccur.Weekly,
                        value: HowOftenDoesThisOccur.Weekly,
                      },
                      {
                        label: HowOftenDoesThisOccur.Monthly,
                        value: HowOftenDoesThisOccur.Monthly,
                      },
                      {
                        label: HowOftenDoesThisOccur.Once,
                        value: HowOftenDoesThisOccur.Once,
                      },
                    ]}
                    // defaultValue={recordType}
                    styles={{
                      ...SelectStyles,
                      input: {
                        ...SelectStyles.input,
                        fontWeight: 400,
                      },
                    }}
                    value={data.howOftenDoesThisOccur}
                    onChange={(option: any) => {
                      setData((prev: any) => ({
                        ...prev,
                        howOftenDoesThisOccur: option,
                      }));
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Estimate end date</InputLabel>
                  <CustomDatePicker
                    clearable={false}
                    styles={{
                      input: {
                        border: "none",
                        fontSize: "1.5rem",
                        padding: "0.2rem 0",
                      },
                    }}
                    value={data.endDate}
                    minDate={data.startDate}
                    onChange={(date: Date) => {
                      let offset = date.getTimezoneOffset();
                      let offsetInHours = offset / 60;
                      let offsetInMilliseconds = offsetInHours * 60 * 60 * 1000;
                      let newDate = new Date(
                        date.getTime() - offsetInMilliseconds
                      );

                      setData((prev: any) => ({
                        ...prev,
                        endDate: moment(newDate).toDate().toISOString(),
                      }));
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Does the amount change over time?</InputLabel>
                  <Select
                    data={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },

                      {
                        label: "No, it's fixed",
                        value: "No, it's fixed",
                      },
                    ]}
                    styles={{
                      ...SelectStyles,
                      input: {
                        ...SelectStyles.input,
                        fontWeight: 400,
                      },
                    }}
                    value={data.doesAmountChange}
                    onChange={(option: any) => {
                      setData((prev: any) => ({
                        ...prev,
                        doesAmountChange: option,
                      }));
                    }}
                  />
                </InputContainer>

                {/* <InputContainer>
                <InputLabel>Cashflow Category</InputLabel>
                <Select
                  clearable={false}
                  data={cashflowBucketOptions}
                  styles={{
                    ...SelectStyles,
                    input: {
                      ...SelectStyles.input,
                      fontWeight: 400,
                    },
                  }}
                  value={data.cashflowCategory}
                  onChange={(option: any) => {
                    setData((prev: any) => ({
                      ...prev,
                      cashflowCategory: option,
                    }));
                  }}
                />
              </InputContainer> */}

                <If condition={contact !== undefined}>
                  <MapCategory
                    mappings={contact?.cashflowCategoryMappings.filter(
                      (mapping: any) =>
                        mapping.scenario.id === selectedScenario?.id
                    )}
                    mode="view"
                  />
                </If>
              </Fragment>
            )}
          </Card>

          <button
            onClick={() => {
              // createBill("create");
              save();
            }}
            type="button"
            style={{
              backgroundColor: COLORS.greenPrimary,
              color: "white",
              width: "25%",
            }}
            className={`button mt-4 is-bold button-invoice ${
              loading ? "is-loading" : ""
            }`}
          >
            Save
          </button>
        </Form>
      )}
    </CustomDrawer>
  );
}

export default AddEditForecastBudgetModal;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Currency = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  position: absolute;
  /* align right */
  right: 0;
  top: 0;
  padding: 0.1rem 0.2rem;
  margin-top: 18px;

  user-select: none;

  background-color: #fff;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

const Separator = styled.div`
  margin: 20px;
  font-size: 20px;
`;

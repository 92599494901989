import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState, useEffect } from "react";
import { Select, Button, LoadingOverlay, Checkbox } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { CustomDrawer } from "../../../../../common/CustomDrawer";
import Card from "../../../../../common/Card/Card";
import styled from "styled-components";
import { COLORS } from "../../../../../constants/theme";
import moment from "moment";
import { InvoiceTypes } from "../../../../../constants/globalConstants";
import axios from "axios";
import _ from "lodash";
import { configs } from "../../../../../constants";
import { showNotification } from "@mantine/notifications";
import { useAuth } from "../../../../../contexts";
import { SelectStyles } from "../../../../../constants/styledComponents";
import { filterContacts } from "../../../../../repositories/contactRepository";
import currencies from "../../../../../assets/files/currencies.json";
import { getUserLS } from "../../../../../modules/localStorage";
import AlertModal from "../../../../../common/AlertModal/AlertModal";

const nativeSelectStyles = {
  fontFamily: "Arial, sans-serif",
  fontSize: "18px",
  fontWeight: "bold",
  color: "#333",
  padding: "10px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  backgroundColor: "white",
  cursor: "pointer",
  "&:focus": {
    outline: "none",
  },
  "& option": {
    padding: "5px",
  },
};

function EditBillsOrInvoices({
  getRecords,
  editInBulk,
  setEditInBulk,
  records,
  setRecords,
  setEditRecordState,
  title,
  isBill,
}: any) {
  const user = getUserLS();

  const {
    recordsReceivable: _records,
    externalAccounts: _externalAccounts,
    fetchExternalAccounts,
  } = useAuth();

  const [formValues, setFormValues] = useState<any>({});

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<any>([]);
  const [selectedName, setSelectedName] = useState("");
  const [externalAccounts, setExternalAccounts] =
    useState<any[]>(_externalAccounts);

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    message: "",
  });

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const initalValues = records.reduce(
      (acc: any, bill: any) => ({
        ...acc,
        [bill.id]: {
          name: bill.name,
          amountTotal: bill.amountTotal,
          currency: bill.currency,
          issueDate: bill.issueDate,
          dueDate: bill.dueDate,
          expectedDueDate: bill.expectedDueDate,
          invoiceNumber: bill.invoiceNumber,
          description: bill.description,
          isDelete: false,
        },
      }),
      {}
    );

    if (isMounted) {
      setFormValues(initalValues);
    }

    return () => {
      isMounted = false;
    };
  }, [records]);

  useEffect(() => {
    setOptions(
      _externalAccounts.map((account: any) => ({
        value: account.displayName,
        label: account.displayName,
        disabled:
          !account.types.includes(contactType) &&
          !account.types.includes("Employee"),
      }))
    );
  }, [_externalAccounts]);

  useEffect(() => {
    if (!selectedName) {
      setExternalAccounts(_externalAccounts);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      const filteredContacts = await filterContacts(selectedName);

      setOptions(
        _.uniqBy(filteredContacts, "id").map((account: any) => ({
          value: account.displayName,
          label: account.displayName,
          disabled:
            !account.types.includes(contactType) &&
            !account.types.includes("Employee"),
        }))
      );
      setExternalAccounts((prev) => _.uniqBy(filteredContacts, "id"));
    }, 0);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedName]);

  let contactType = InvoiceTypes.Payable ? "Vendor" : "Customer";

  const handleOnBack = () => {
    setEditRecordState([]);
    setEditInBulk(false);
  };

  const handleOnClose = () => {
    setEditRecordState([]);
    setEditInBulk(false);
  };

  const handleChange = (billId: any, field: any, value: any) => {
    setFormValues((prev: any) => ({
      ...formValues,
      [billId]: {
        ...prev[billId],
        [field]: value,
      },
    }));
  };

  const validateData = () => {
    let errors: any = [];
    let deleteRows: any = [];

    const formKeys = Object.keys(formValues);

    formKeys.forEach((key, index) => {
      const item = formValues[key];

      if (item.isDelete) {
        deleteRows.push(
          `Are you sure you want to delete the record at row no. ${index + 1}?`
        );
      } else {
        if (!item.name) errors.push(`Contact name is a required field.`);
        if (!item.amountTotal) errors.push(`Amount is a required field.`);
        if (!item.currency) errors.push(`Currency is a required field.`);
        if (!item.issueDate) errors.push(`Issue date is a required field.`);
        if (!item.dueDate) errors.push(`Due date is a required field.`);
        if (!item.expectedDueDate)
          errors.push(`Expected due date is a required field.`);

        const issueDate = new Date(item.issueDate);
        const dueDate = new Date(item.dueDate);
        const expectedDueDate = new Date(item.expectedDueDate);

        if (issueDate > dueDate) {
          errors.push(`Issue date must be less than the due date.`);
        }
        if (expectedDueDate < issueDate) {
          errors.push(
            `Row ${
              index + 1
            }: Expected due date must be greater than the issue date.`
          );
        }
      }
    });

    if (errors.length > 0) {
      setModalState({
        open: true,
        title: "Invalid data!",
        message: errors.join("\n"),
      });
      return false;
    }

    if (deleteRows.length > 0) {
      setDeleteModal(true);
      setModalState({
        open: true,
        title: "Warning!",
        message: "Are you sure you want to delete the selected record(s)?",
      });
      return false;
    }

    return true;
  };

  const onSave = async (confirm: boolean) => {
    try {
      if (!confirm && !validateData()) {
        return;
      }

      setLoading(true);
      modalReset();

      const response = await axios.patch(
        configs.urls.BASE_URL + `/invoice/updateInBulk`,
        {
          formValues,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        showNotification({
          color: "teal",
          message: response.data.message,
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
        getRecords();
        handleOnClose();
        setDeleteModal(false);
      }
    } catch (err) {
      console.log("Error in bulk saving: ", err);
    } finally {
      setLoading(false);
    }
  };

  const modalReset = () => {
    setModalState({
      open: false,
      title: "",
      message: "",
    });

    setDeleteModal(false);
  };

  return (
    <CustomDrawer
      // zIndex={1000}
      opened={editInBulk}
      onBack={handleOnBack}
      onClose={handleOnClose}
      title={title}
    >
      <Fragment>
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"></div>
          <LoadingOverlay
            visible={loading}
            loaderProps={{
              color: COLORS.greenBlue,
              variant: "oval",
            }}
          />
          <Card
            className="table-container mb-3"
            style={{
              position: "relative",
            }}
          >
            <div
              className="table-container custom-scrollbar mb-3"
              style={{
                height: "calc(100vh - 280px)",
                overflowY: "scroll",
              }}
            >
              <table
                style={{ borderTop: "1px solid #e0e0e0" }}
                className="table is-bordered is-striped is-hoverable is-fullwidth is-relative "
              >
                <thead>
                  <tr
                    style={{
                      fontWeight: "700",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 9,
                    }}
                  >
                    <TableHeader>Delete</TableHeader>
                    <TableHeaderV2>
                      {isBill ? "Name*" : "Customer Name*"}{" "}
                    </TableHeaderV2>
                    <TableHeader>Amount*</TableHeader>
                    <TableHeaderV2>Currency*</TableHeaderV2>
                    <TableHeader>Issue Date*</TableHeader>
                    <TableHeader>Due Date*</TableHeader>
                    <TableHeader>Expected Due Date*</TableHeader>
                    <TableHeader>
                      {isBill ? "Bill No." : "Invoice No."}
                    </TableHeader>
                    <TableHeader>Note to Self</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {records.map((bill: any) => {
                    return (
                      <tr key={bill.id}>
                        <TableData className="p-3">
                          <Checkbox
                            style={{ zIndex: 0 }}
                            checked={formValues[bill.id]?.isDelete}
                            onChange={(e) =>
                              handleChange(
                                bill.id,
                                "isDelete",
                                e.currentTarget.checked
                              )
                            }
                          />
                        </TableData>
                        <TableData className="p-3">
                          <Select
                            disabled={formValues[bill.id]?.isDelete}
                            name="name"
                            defaultValue={"AED"}
                            value={formValues[bill.id]?.name}
                            onChange={(value: string) => {
                              setFormValues((prev: any) => ({
                                ...formValues,
                                [bill.id]: {
                                  ...prev[bill.id],
                                  ["name"]: value,
                                },
                              }));
                            }}
                            styles={{
                              input: {
                                // marginTop: 1,
                                fontWeight: "bold",
                                fontSize: "14px !important",
                              },
                            }}
                            data={options}
                            searchable
                            maxDropdownHeight={400}
                            nothingFound="Nothing here"
                            filter={(value, item: any) =>
                              item.label
                                .toLowerCase()
                                .includes(value.toLowerCase().trim()) ||
                              item.value
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                          />
                        </TableData>
                        <TableData>
                          <Input
                            disabled={formValues[bill.id]?.isDelete}
                            type="number"
                            onFocus={(event) => event.target.select()}
                            name="amountTotal"
                            value={formValues[bill.id]?.amountTotal}
                            onChange={(e) =>
                              handleChange(
                                bill.id,
                                "amountTotal",
                                e.target.value
                              )
                            }
                          />
                        </TableData>
                        <TableData>
                          <Select
                            disabled={formValues[bill.id]?.isDelete}
                            name="currency"
                            defaultValue={"AED"}
                            value={formValues[bill.id]?.currency}
                            onChange={(value: string) => {
                              setFormValues((prev: any) => ({
                                ...formValues,
                                [bill.id]: {
                                  ...prev[bill.id],
                                  ["currency"]: value,
                                },
                              }));
                            }}
                            styles={{
                              input: {
                                marginTop: 4,
                                fontWeight: "bold",
                                fontSize: "14px !important",
                              },
                            }}
                            data={currencies}
                            searchable
                            maxDropdownHeight={400}
                            nothingFound="Nothing here"
                            filter={(value, item: any) =>
                              item.label
                                .toLowerCase()
                                .includes(value.toLowerCase().trim()) ||
                              item.value
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                          />
                        </TableData>
                        <TableData className="p-3">
                          <CustomDatePicker
                            disabled={formValues[bill.id]?.isDelete}
                            clearable={false}
                            styles={{
                              input: {
                                minWidth: "130px !important",
                                border: "none",
                                fontWeight: "bolder",
                                fontSize: "14px !important",
                              },
                            }}
                            value={new Date(formValues[bill.id]?.issueDate)}
                            onChange={(date: any) => {
                              handleChange(
                                bill.id,
                                "issueDate",
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </TableData>
                        <TableData className="p-3">
                          {" "}
                          <CustomDatePicker
                            disabled={formValues[bill.id]?.isDelete}
                            clearable={false}
                            minDate={
                              user.isDemo
                                ? new Date(
                                    moment(user.createdAt)
                                      .subtract(6, "M")
                                      .startOf("month")
                                      .format("YYYY-MM-DD hh:mm")
                                  )
                                : new Date(user.createdAt)
                            }
                            styles={{
                              input: {
                                minWidth: "130px !important",
                                border: "none",
                                fontWeight: "bolder",
                                fontSize: "14px !important",
                              },
                            }}
                            value={new Date(formValues[bill.id]?.dueDate)}
                            onChange={(date: any) => {
                              handleChange(
                                bill.id,
                                "dueDate",
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </TableData>
                        <TableData className="p-3">
                          {" "}
                          <CustomDatePicker
                            disabled={formValues[bill.id]?.isDelete}
                            clearable={false}
                            minDate={
                              user.isDemo
                                ? new Date(
                                    moment(user.createdAt)
                                      .subtract(6, "M")
                                      .startOf("month")
                                      .format("YYYY-MM-DD hh:mm")
                                  )
                                : new Date(user.createdAt)
                            }
                            styles={{
                              input: {
                                minWidth: "130px !important",
                                border: "none",
                                fontWeight: "bolder",
                                fontSize: "14px !important",
                              },
                            }}
                            value={
                              new Date(formValues[bill.id]?.expectedDueDate)
                            }
                            onChange={(date: any) => {
                              handleChange(
                                bill.id,
                                "expectedDueDate",
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </TableData>
                        <TableData className="p-3">
                          <Input
                            disabled={formValues[bill.id]?.isDelete}
                            name="invoiceNumber"
                            value={formValues[bill.id]?.invoiceNumber}
                            onChange={(e) =>
                              handleChange(
                                bill.id,
                                "invoiceNumber",
                                e.target.value
                              )
                            }
                          />
                        </TableData>
                        <TableData className="p-3">
                          <TextArea
                            disabled={formValues[bill.id]?.isDelete}
                            name="description"
                            value={formValues[bill.id]?.description}
                            onChange={(e) =>
                              handleChange(
                                bill.id,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </TableData>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            disabled={loading}
            className={`button mv-6 is-bold is-small ${
              loading ? "is-loading" : ""
            }`}
            style={{
              backgroundColor: COLORS.greenBlue,
              color: COLORS.white,
              width: "15rem",
            }}
            onClick={() => {
              onSave(false);
            }}
          >
            Save
          </button>
        </div>
      </Fragment>

      {modalState && (
        <>
          <AlertModal
            loading={loading}
            isDelete={deleteModal}
            modalOpened={modalState.open}
            confirmDelete={() => {
              onSave(true);
            }}
            resetModal={modalReset}
            title={modalState.title}
            message={modalState.message}
          />
        </>
      )}
    </CustomDrawer>
  );
}

export default EditBillsOrInvoices;

const TableData = styled.td`
  font-size: 14px;
  text-align: left;
  /* :sec-child {
    min-width: 120px;
  } */
`;

const TableHeader = styled.th`
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.brownGrey};
  text-align: left;
  height: 20px;
  vertical-align: middle;
  /* :first-child {
    min-width: 120px;
  } */
`;

const TableHeaderV2 = styled.th`
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.brownGrey};
  text-align: left;
  height: 20px;
  vertical-align: middle;
  min-width: 150px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 14px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: auto;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 14px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #707070;

  .mantine-DatePicker-dropdown {
    width: 455px !important;
  }
`;

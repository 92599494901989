import { DatePicker } from "@mantine/dates";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import Card from "../../../../common/Card/Card";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import DetailsCardFooter from "../../../../common/PageLayout/components/DetailsCardFooter";
import DetailsCardHeader from "../../../../common/PageLayout/components/DetailsCardHeader";
import EditModal from "../../../../common/PageLayout/components/EditModal";
import LabelValue from "../../../../common/PageLayout/components/LabelValue";
import ManageRunwayCard from "../../../../common/PageLayout/components/ManageRunwayCard";
import ShowDetailsCard from "../../../../common/PageLayout/components/ShowDetailsCard";
import { configs, IMAGES, StyledComponents } from "../../../../constants";
import {
  InvoiceTypes,
  PaymentStatus,
} from "../../../../constants/globalConstants";
import { COLORS } from "../../../../constants/theme";
import { InvoiceProviders } from "../../../../constants/types";
import { useAuth } from "../../../../contexts";
import { formatCurrency } from "../../../../modules";
import { getInvoiceStatus } from "../../../../modules/getInvoiceStatus";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";
import { usePaymentsContext } from "../../contexts/PaymentsContext";
import { updateRecord } from "../../repository";
import OpenBalance from "../common/OpenBalance";
import AddEditForecastBudgetModal from "../../../Cashflow/components/ForecastBudget/AddEditForecastBudgetModal";
import { Helmet } from "react-helmet";
import { MapCategory } from "../../../Contacts/components/MapCategory";
import { If } from "../../../../common/Utils/If";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function RightView() {
  const [loading, setLoading] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<boolean>(true);
  const { setSearchParams } = usePaymentsContext();

  const [isEstimates, setIsEstimates] = useState(false);

  const { recordsReceivable: records, forecasts } = useAuth();
  const [searchParams] = useSearchParams();
  const selectedCompanyId = searchParams.get("selectedBillId");
  const selectedForecastId = searchParams.get("selectedForecastId");

  let isForecastOrBudget = selectedForecastId ? true : false;

  const [selectedBill, setSelectedBill] = useState<any>({});
  const [showEditPayModal, setShowEditPayModal] = React.useState(false);

  const [showImage, setShowImage] = React.useState(false);
  const [showMore, setShowMore] = React.useState(true);
  const [editDateFieldShown, showEditDateField] = React.useState(false);
  const [editedExpectedDueDate, setEditedExpectedDueDate] =
    React.useState<any>();

  const [contactId, setContactId] = useState<any>();

  const [editForecastBudgetModal, setEditForecastBudgetModal] =
    React.useState(false);
  const [isDetailsOnlyModal, setIsDetailsOnlyModal] = React.useState(false);

  const query = useQuery();

  useEffect(() => {
    const tab = query.get("tab");
    if (tab === "estimates") {
      setIsEstimates(true);
    } else {
      setIsEstimates(false);
    }
  }, [query]);

  useEffect(() => {
    const invoiceIdFromStorage = localStorage.getItem("selectedInvoiceId");
    const forecastIdFromStorage = localStorage.getItem(
      "selectedForecastIdGetPaid"
    );

    if ((selectedForecastId || forecastIdFromStorage) && forecasts?.length) {
      setLoading(true);
      setPlaceholder(false);
      let searchId = selectedForecastId || forecastIdFromStorage;
      const forecast = forecasts.find((record: any) => record.id == searchId);
      if (forecast) {
        setSelectedBill(forecast);
      } else {
        setSelectedBill(forecasts[0]);
      }
      // setContactId(forecast?.contact?.id);
      setSearchParams({
        tab: searchParams.get("tab") || "all",
        selectedForecastId: forecast?.id,
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else if (
      (selectedCompanyId || invoiceIdFromStorage) &&
      records.length > 0
    ) {
      setLoading(true);
      setPlaceholder(false);
      if (invoiceIdFromStorage) {
        const invoice = records.find(
          (record: any) => record.id == invoiceIdFromStorage
        );
        // console.log("Invoice dataaa >>> ", invoice);
        if (invoice) {
          setSelectedBill(invoice);
          setContactId(invoice?.contact?.id);
          setSearchParams({
            tab: searchParams.get("tab") || "all",
            selectedBillId: invoiceIdFromStorage,
          });
        } else {
          setSelectedBill(records[0]);
          setContactId(records[0]?.contact?.id);
          setSearchParams({
            tab: searchParams.get("tab") || "all",
            selectedBillId: records[0].id,
          });
        }
      } else {
        const invoice = records.find(
          (record: any) => record.id == selectedCompanyId
        );
        setSelectedBill(invoice);
        setContactId(invoice?.contact?.id);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);

      return () => {
        showEditDateField(false);
        setEditedExpectedDueDate(undefined);
      };
    }
  }, [selectedCompanyId, records, selectedForecastId]);

  const renderTopHeader = () => (
    <StyledComponents.ListItemCardHeaderContainer>
      {loading || placeholder ? (
        <span className="mr-3">
          <LoadingPlaceholders.Avatar
            style={{ marginBottom: -5, marginLeft: 3 }}
            active={loading}
          />
        </span>
      ) : (
        <StyledComponents.ImageOnListItemCard
          src={IMAGES.company_logo_placeholder}
        />
      )}

      <div>
        <StyledComponents.NameOnListItemCard>
          {loading || placeholder ? (
            <LoadingPlaceholders.Text
              style={{ marginBottom: -5, marginLeft: 3, height: 25 }}
              active={loading}
            />
          ) : (
            selectedBill?.name
          )}
        </StyledComponents.NameOnListItemCard>
        {loading || placeholder ? (
          <LoadingPlaceholders.Text
            style={{ marginBottom: -5, marginLeft: 3, height: 15 }}
            active={loading}
            width={80}
          />
        ) : isForecastOrBudget ? (
          <div>Estimate</div>
        ) : (
          <div style={{ fontSize: 10, fontWeight: "600" }}>
            {_.isEmpty(selectedBill?.invoiceNumber) ||
            !selectedBill?.invoiceNumber.trim() ? (
              <i>No invoice number.</i>
            ) : (
              `Invoice # ${selectedBill?.invoiceNumber}`
            )}
          </div>
        )}
      </div>
    </StyledComponents.ListItemCardHeaderContainer>
  );

  const whichSource = (provider: string) => {
    switch (provider) {
      case InvoiceProviders.None:
        return "Created on MADI";
      case InvoiceProviders.Transaction:
        return "Added from Transactions";
      default:
        return `Synced from ${provider}`;
    }
  };
  const handleEditPay = () => {
    setShowEditPayModal(true);
  };

  const renderBillOrInvoice = () => (
    <Fragment>
      <Card className="details-card-container">
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={handleEditPay}
          hasBadge={getInvoiceStatus(selectedBill?.status)}
          buttonDisabled={loading || placeholder}
          title="Invoice Details"
        />
        <EditModal
          showEditPayModal={showEditPayModal}
          setShowEditPayModal={setShowEditPayModal}
          selectedBill={selectedBill}
          invoiceType={InvoiceTypes.Receivable}
          title="Edit Invoice"
        />
        <div className="flex-direction-row-space-between mt-4">
          <div>
            <LabelValue
              label="Customer Name"
              value={selectedBill?.name}
              loading={loading}
              placeholder={placeholder}
              linkTo={
                contactId &&
                `/contacts?tab=vendor&selectedContactId=${contactId}`
              }
            />
            {/* {console.log("Selected Bill Amount >>> ", selectedBill)} */}
            {/* {isEstimates ? (
              <LabelValue
                label="Total Amount"
                value={`${selectedBill?.currency} ${
                  selectedBill?.amount || "0"
                }`}
                loading={loading}
                placeholder={placeholder}
              />
            ) : ( */}
            <LabelValue
              label="Total Amount"
              value={`${selectedBill?.currency} ${formatCurrency(
                parseFloat(selectedBill?.amountTotal || "0"),
                2
              )}`}
              loading={loading}
              placeholder={placeholder}
            />
            {/* )} */}
            <LabelValue
              label="Due Date"
              value={moment(selectedBill?.dueDate).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
            />
            <LabelValue
              label="Issue Date"
              value={moment(selectedBill?.issueDate).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
            />
          </div>

          {/* File/Image */}
          {loading || placeholder ? (
            <LoadingPlaceholders.Avatar
              isSquare
              active={loading}
              style={{ width: 200, height: 270 }}
            />
          ) : (
            selectedBill?.image && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setShowImage(true)}
              >
                <img
                  src={`${configs.urls.BASE_URL}/static${selectedBill?.image}`}
                  alt=""
                  style={{
                    width: 200,
                    height: 270,
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                />
              </div>
            )
          )}
        </div>
        <LabelValue
          label="Invoice No"
          value={selectedBill?.invoiceNumber}
          loading={loading}
          placeholder={placeholder}
        />

        {loading || placeholder ? (
          <div className="mb-5">
            <LoadingPlaceholders.Text
              style={{ marginBottom: -8, marginLeft: 3, height: 15 }}
              active={loading}
              width={80}
            />
            <LoadingPlaceholders.Text
              style={{ marginBottom: -5, marginLeft: 3 }}
              active={loading}
              width={380}
            />
          </div>
        ) : (
          <div className="mb-5" style={{ width: "500px" }}>
            <div style={{ fontSize: 12, fontWeight: "600" }}>Note to Self</div>
            <div
              style={{
                fontSize: 20,
              }}
            >
              {_.isEmpty(selectedBill?.description) ||
              !selectedBill?.description.trim() ? (
                <i style={{ fontSize: 12 }}>No note to self.</i>
              ) : selectedBill?.description.length > 80 ? (
                showMore ? (
                  <span>
                    {selectedBill?.description.slice(0, 80)}...{" "}
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: COLORS.greenPrimary,
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      see more
                    </span>
                  </span>
                ) : (
                  <span>
                    {selectedBill?.description}
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: COLORS.greenPrimary,
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      {"  "}see less
                    </span>
                  </span>
                )
              ) : (
                selectedBill?.description
              )}
            </div>
          </div>
        )}

        {selectedBill?.linkedTransactions?.length &&
        (selectedBill?.status === PaymentStatus.PartiallyPaid ||
          selectedBill?.status === PaymentStatus.Paid) ? (
          <Fragment>
            <LabelValue
              label={"Paid at"}
              value={moment(
                getLatestTransaction(selectedBill?.linkedTransactions)
                  ?.paidOrFailedAt
              ).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
              labelUnderValue={
                selectedBill?.linkedTransactions.length + " Payment(s)"
              }
              renderHoverableDropdown={() => (
                <div
                  style={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                  }}
                >
                  {selectedBill?.linkedTransactions.map(
                    (transaction: any, index: number) => (
                      <div
                        key={transaction.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: "5px 0",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Ref: {transaction.txnReferenceId}
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                          <div style={{ fontSize: 14, fontWeight: "600" }}>
                            {moment(transaction.paidOrFailedAt).format(
                              "MMM DD, YYYY"
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {/* {selectedBill?.currency}{" "} */}
                            {transaction?.currency}{" "}
                            <span
                              style={{
                                fontWeight: "600",
                                color: COLORS.greenPrimary,
                              }}
                            >
                              {formatCurrency(
                                parseFloat(transaction.amountPaid || "0"),
                                2
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            />
            {selectedBill?.authorizedAt && (
              <LabelValue
                label="Authorized at"
                value={moment(
                  getLatestTransaction(selectedBill?.linkedTransactions)
                    ?.paidOrFailedAt
                ).format("MMM DD, YYYY")}
                loading={loading}
                placeholder={placeholder}
              />
            )}
            {selectedBill?.authorizedBy && (
              <LabelValue
                label="Authorized by"
                value={selectedBill?.authorizedBy}
                loading={loading}
                placeholder={placeholder}
              />
            )}
          </Fragment>
        ) : null}

        {selectedBill?.creditNotes?.length ? (
          <LabelValue
            label={"Credit notes applied"}
            value={moment(selectedBill?.creditNotes[0]?.date).format(
              "MMM DD, YYYY"
            )}
            loading={loading}
            placeholder={placeholder}
            labelUnderValue={
              selectedBill?.creditNotes.length + "  Credit Note(s)"
            }
            renderHoverableDropdown={() => (
              <div
                style={{
                  overflowY: "hidden",
                  overflowX: "hidden",
                }}
              >
                {selectedBill?.creditNotes.map((note: any, index: number) => (
                  <div
                    key={note.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "5px 0",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontWeight: "600",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Ref: {note.creditNoteNumber}
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                      <div style={{ fontSize: 14, fontWeight: "600" }}>
                        {moment(note.date).format("MMM DD, YYYY")}
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {selectedBill?.currency}{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            color: COLORS.greenPrimary,
                          }}
                        >
                          {formatCurrency(parseFloat(note.amount || "0"), 2)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        ) : null}

        <LabelValue
          label="Source"
          value={whichSource(selectedBill?.provider)}
          loading={loading}
          placeholder={placeholder}
          isValueBold
        />

        {/* Modal */}
        {showImage && (
          <div className="modal is-active" style={{ zIndex: 9 }}>
            <div className="modal-background"></div>
            <div className="modal-content">
              <p className="image is-4by2">
                <img
                  src={`${configs.urls.BASE_URL}/static${selectedBill?.image}`}
                  alt=""
                />
              </p>
            </div>
            <button
              onClick={() => setShowImage(false)}
              className="modal-close is-large"
              aria-label="close"
            ></button>
          </div>
        )}

        <OpenBalance
          loading={loading}
          placeholder={placeholder}
          selectedBill={selectedBill}
        />
      </Card>
      <ManageRunwayCard
        record={selectedBill}
        loading={loading}
        placeholder={placeholder}
      />
    </Fragment>
  );

  const renderForecastOrBudget = () => (
    <Fragment>
      <Card className="details-card-container" style={{ marginBottom: 20 }}>
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={() => {
            setIsDetailsOnlyModal(true);
            setEditForecastBudgetModal(true);
          }}
          hasBadge={false}
          buttonDisabled={loading || placeholder}
          title="Estimate Details"
        />

        <LabelValue
          label="Estimate name"
          value={selectedBill?.name}
          loading={loading}
          placeholder={placeholder}
        />

        {isEstimates ? (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${selectedBill?.amount || "0"}`}
            loading={loading}
            placeholder={placeholder}
          />
        ) : (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${formatCurrency(
              parseFloat(selectedBill?.settings?.amount || "0"),
              2
            )}`}
            loading={loading}
            placeholder={placeholder}
          />
        )}
        <LabelValue
          label="Current estimate date"
          value={moment(selectedBill?.dueDate).format("MMM DD, YYYY")}
          loading={loading}
          placeholder={placeholder}
        />
      </Card>

      <Card className="details-card-container">
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={() => {
            setIsDetailsOnlyModal(false);
            setEditForecastBudgetModal(true);
          }}
          hasBadge={false}
          buttonDisabled={loading || placeholder}
          title="Estimate Settings"
        />

        <LabelValue
          label="Estimate name"
          value={selectedBill?.name}
          loading={loading}
          placeholder={placeholder}
        />

        {isEstimates ? (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${selectedBill?.amount || "0"}`}
            loading={loading}
            placeholder={placeholder}
          />
        ) : (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${formatCurrency(
              parseFloat(selectedBill?.settings?.amount || "0"),
              2
            )}`}
            loading={loading}
            placeholder={placeholder}
          />
        )}

        <LabelValue
          label="Starting estimate date"
          value={moment(selectedBill?.settings?.startForecastDate).format(
            "MMM DD, YYYY"
          )}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="End estimate date"
          value={moment(selectedBill?.settings?.endForecastDate).format(
            "MMM DD, YYYY"
          )}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="Repeats"
          value={selectedBill?.settings?.repeats}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="Does the amount change over time?"
          value={
            selectedBill?.settings?.valueForChangeInAmount
              ? "Yes"
              : "No, it’s Fixed"
          }
          loading={loading}
          placeholder={placeholder}
        />

        {/* <LabelValue
          label="Cashflow category"
          value={selectedBill?.settings?.cashflowCategory}
          loading={loading}
          placeholder={placeholder}
        /> */}

        <If
          condition={
            selectedBill?.settings?.cashflowCategoryMappings?.length > 0 &&
            !loading &&
            !placeholder
          }
        >
          <MapCategory
            mode="view"
            mappings={selectedBill?.settings?.cashflowCategoryMappings}
            showTitle={false}
          />
        </If>

        {/* <LabelValue
          label="Cashflow category"
          value={selectedBill?.settings.repeats}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="Budget"
          value={selectedBill?.settings.repeats}
          loading={loading}
          placeholder={placeholder}
        /> */}
      </Card>

      <AddEditForecastBudgetModal
        fromEstimate={true}
        opened={editForecastBudgetModal}
        onClose={() => setEditForecastBudgetModal(false)}
        type="forecast"
        isDetailsOnly={isDetailsOnlyModal}
        data={selectedBill}
        section="Receivable"
        editForecast={true}
      />
    </Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <title>{selectedBill?.name}</title>
      </Helmet>
      <ShowDetailsCard
        renderTopHeader={renderTopHeader}
        renderCard={
          selectedForecastId ? renderForecastOrBudget : renderBillOrInvoice
        }
      />
    </Fragment>
  );
}

export default RightView;
